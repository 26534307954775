import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthorizeGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.authService.setIsAuthorize(state.url);
    return this.authService.isAuthorize.pipe(
      take(1),
      map((res: boolean) => {
        if (!res) {
          console.log('page-not-found with AuthorizeGuard');
          this.router.navigate(['/page-not-found']);
          return false;
        } else {          
        return true;
        }
      })
    );
  }
}
