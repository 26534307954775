import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-alert-box',
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.scss']
})
export class AlertBoxComponent implements OnInit {

  constructor() { }

  ngOnInit() {  }

  animateClose:boolean = false;
  isChangeError:boolean = true;
  
  @Input() errorMessage: any[] = [];
  @Input() successMessage: any[] = [];


  closeError(elementToDelete:number): void {
    this.errorMessage[elementToDelete].isDeleted = true

    setTimeout(() => {
      this.errorMessage.splice(elementToDelete, 1);

    }, 1000);
  }

  closeSuccess(elementToDelete:number): void {
    this.successMessage[elementToDelete].isDeleted = true

    setTimeout(() => {
      this.successMessage.splice(elementToDelete, 1);

    }, 1000);
  }
}
