import { Injectable } from '@angular/core';
import { consts } from 'src/app/core/utils/const';
import { HttpClient } from '@angular/common/http';
import { ResDTO, Tbl } from './common-model';
import { tap, catchError } from 'rxjs/operators';
import { Observable, Subject, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private emitProfileSearch = new Subject<any>();
  public searchEmitted$ = this.emitProfileSearch.asObservable();
  public commonWarningMsg = 'Something went wrong';
  public amcAutoCompleteData = [] as any;
  targetMktShape = [{ targetId: 1, shapeName: 'circle', targetName: 'Eligible Counterparty' },
  { targetId: 2, shapeName: 'square', targetName: 'Professional' },
  { targetId: 3, shapeName: 'triangle', targetName: 'Retail' }] as any;

  constructor(private http: HttpClient, private toastr: ToastrService,
    private router: Router
  ) {
    toastr.toastrConfig.maxOpened = 1;
    toastr.toastrConfig.preventDuplicates = true;
  }

  get amc_id(): number {
    return Number(localStorage.getItem(consts.LocalStore.amcId));
  }

  set amc_id(amcId: number) {
    Number(localStorage.setItem(consts.LocalStore.amcId, amcId.toString()));
  }

  get user_id(): number {
    return Number(localStorage.getItem(consts.LocalStore.userID));
  }

  set user_id(user_id: number) {
    Number(localStorage.setItem(consts.LocalStore.userID, user_id.toString()));
  }

  get isLightTheme(): any {
    return localStorage.getItem(consts.LocalStore.is_light_theme) === 'true' ? true : false;
  }

  set isLightTheme(data: any) {
    localStorage.setItem(consts.LocalStore.is_light_theme, data.toString());
  }

  get amc_name(): string {
    return String(localStorage.getItem(consts.LocalStore.amc_name));
  }

  set amc_name(data: string) {
    localStorage.setItem(consts.LocalStore.amc_name, data);
  }

  public get lstSelectedFltr(): any {
    return localStorage.getItem(consts.LocalStore.lstSelectedFltr);
  }

  public set lstSelectedFltr(data: any) {
    localStorage.setItem(consts.LocalStore.lstSelectedFltr, JSON.stringify(data));
  }

  public get asOnDate(): string {
    return String(localStorage.getItem(consts.LocalStore.asOnDate));
  }

  public set asOnDate(data: string) {
    localStorage.setItem(consts.LocalStore.asOnDate, data);
  }

  public get lstPageMst(): any {
    return localStorage.getItem(consts.LocalStore.lstPageMst);
  }

  public set lstPageMst(data: any) {
    localStorage.setItem(consts.LocalStore.lstPageMst, JSON.stringify(data));
  }

  get currentPageId(): number {
    return Number(localStorage.getItem(consts.LocalStore.currentPageId)) === 0 ? 1 : Number(localStorage.getItem(consts.LocalStore.currentPageId));
  }

  set currentPageId(currentPageId: number) {
    Number(localStorage.setItem(consts.LocalStore.currentPageId, currentPageId.toString()));
  }

  get defaultPage(): any {
    return localStorage.getItem(consts.LocalStore.defaultPage);
  }

  set defaultPage(defaultPage: any) {
    localStorage.setItem(consts.LocalStore.defaultPage, defaultPage);
  }

  get role(): any {
    return localStorage.getItem(consts.LocalStore.role);
  }

  get isTrialUser(): boolean {
    let res = false; const isTrialUser = localStorage.getItem(consts.LocalStore.isTrialUser);
    if (isTrialUser === null) {
      res = false;
    } else {
      res = JSON.parse(isTrialUser)
    }
    return res;
  }

  get eagleEyeBalance(): number {
    return localStorage.getItem(consts.LocalStore.eagleEyeBalance) === null ? 0 : Number(localStorage.getItem(consts.LocalStore.eagleEyeBalance));
  }

  set eagleEyeBalance(eagleEyeBalance: number) {
    Number(localStorage.setItem(consts.LocalStore.eagleEyeBalance, eagleEyeBalance.toString()));
  }

  get reportBalance(): number {
    return localStorage.getItem(consts.LocalStore.reportBalance) === null ? 0 : Number(localStorage.getItem(consts.LocalStore.reportBalance));
  }

  set reportBalance(reportBalance: number) {
    Number(localStorage.setItem(consts.LocalStore.reportBalance, reportBalance.toString()));
  }

  get(api: string) {
    return this.http.get<ResDTO>(api).pipe(
      tap((data) => {
        switch (data.statusCode) {
          case 200:
            return data.data;
          case 403:
            this.tokenExpire();
            break;
          default:
           // this.showWarning('Something went wrong', 'Server Error');
            console.log('issue in api:- ' + api, data);
           // return throwError(data);
        }
      }),
      /*catchError(data => {
        return throwError(data);
      })*/
    );
  }

  post(api: string, reqData: any) {
    return this.http.post<ResDTO>(api, reqData).pipe(
      tap((data) => {
        switch (data.statusCode) {
          case 200:
            return data.data;
          case 403:
            this.tokenExpire();
            break;
          default:
            //this.showWarning('Something went wrong', 'Server Error');
            console.log('issue in api:- ' + api, data);
        //    return throwError(data);
        }
      }),
     /* catchError(data => {
        return throwError(data);
      })*/
    );
  }

  emitProfileChange(change: any) {
    this.emitProfileSearch.next(change);
  }

  showSuccess(message: string, title: string) {
    this.toastr.success(message, title);
  }

  showError(message: string, title: string) {
  //  this.toastr.error(message, title);
  }

  showInfo(message: string, title: string) {
    this.toastr.info(message, title);
  }

  showWarning(message: string, title: string) {
  //  this.toastr.warning(message, title);
  }

  tokenExpire() {
    this.router.navigate(['login']);
    this.showInfo('', 'Login expire');
    localStorage.clear();
  }

  logError(ex: any) {
    const req: ErrorDTO = {
      OriginalErrorMessage: ex.name,
      StackTrack: ex.stack,
      ErrorMessage: ex.message
    };
    this.post(consts.api.common.logError, req).subscribe((res) => {
    });
  }

  logUserActivity(LogUserActivity_DTO: LogUserActivity_DTO) {
    this.post(consts.api.common.logUserActivity, LogUserActivity_DTO).subscribe();
  }

  isModuleAllow(moduleName: string) {
    let res = false;
    try {
      if (this.lstPageMst !== null) {
        const tmp = JSON.parse(this.lstPageMst).filter((v: any) => {
          return v.page_name === moduleName;
        });
        if (tmp.length > 0) { res = true; }
      }
    } catch {
      res = false;
    }
    return res;
  }

  getTargetMktShape(id: number) {
    let res = 'circle';
    const tmp = this.targetMktShape.filter((v: any) => {
      return v.targetId === id;
    });
    if (tmp.length === 1) {
      res = tmp[0].shapeName;
    }
    return res;
  }

  getTargetMktShapeByName(name: number) {
    let res = 'circle';
    const tmp = this.targetMktShape.filter((v: any) => {
      return v.targetName === name;
    });
    if (tmp.length === 1) {
      res = tmp[0].shapeName;
    }
    return res;
  }

  setModuleWiseColumnState(userId: number, moduleId: number, columnJson: any) {
    let req = {
      userId: userId,
      pageId: moduleId,
      columnJson: columnJson
    }
    this.post(consts.api.common.setColumnState, req).subscribe();
  }

  getModuleWiseColumnState(userId: number, moduleId: number): Observable<string> {
    let req = {
      userId: userId,
      pageId: moduleId
    }
    var subject = new Subject<string>();
    this.post(consts.api.common.getColumnState, req).subscribe((res: any) => {
      subject.next(res);
    })
    return subject.asObservable();
  }
}

interface ErrorDTO {
  StatusCode?: 400;
  OriginalErrorMessage: '';
  StackTrack: '';
  ErrorMessage: '';
  Path?: '';
}

export interface LogUserActivity_DTO {
  userId: number;
  pageId: number;
  dataIdentifierId: number;
  dataValueId?: number;
}



