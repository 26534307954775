import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { SharedService } from '../../shared.service';

@Component({
    template: `
   <div class="not-found-container">
        <img src="../../assets/images/404.png" alt="404 Page not Found" loading="lazy" class="not-found-img" title="404 Page not Found" />
        <h1 class="not-found-main-header">Oops ! Page not found</h1>
        <h1 class="not-found-subheader">Sorry we can’t find the page you are looking for</h1>
        <button type="button" class="not-found-back-button mr-sm-4" (click)="_location.back();">Back</button>
        <button type="button" class="not-found-home-button" (click)="home();">Home</button>
   </div>
    `,
    styles: [`
        .not-found-container {
            margin: 5rem auto 0;
            text-align: center;
        }
        .not-found-img {
            width: 32rem;
            height: 18rem;
        }
        .not-found-main-header {
            font-weight: 600;
            font-size: 2.25rem;
            line-height: 2.75rem;
            margin-top: 3.75rem;
        }
        .not-found-subheader {
            font-weight: 600;
            font-size: 1.75rem;
            line-height: 2rem;
            margin-top: 1.625rem;
            color: rgb(142, 142, 142);
            color: var(--grey);
            opacity: 0.7;
        }
        .not-found-back-button {
            margin-top: 3.75rem;
            border: 1px solid rgb(0, 90, 235);
            border: 1px solid var(--primary);
            border-radius: 38px;
            padding: 1rem 3.75rem;
            color: rgb(0, 90, 235);
            color: var(--primary);
        }

        .not-found-back-button:hover {
            margin-top: 3.75rem;
            border: 1px solid rgb(0, 90, 235);
            border: 1px solid var(--primary);
            border-radius: 38px;
            padding: 1rem 3.75rem;
            background-color: rgb(0, 90, 235);
            background-color: var(--primary);
            color: #fff;
            color: var(--white);
        }
        .not-found-home-button {
            margin-top: 3.75rem;
            border: 1px solid rgb(0, 90, 235);
            border: 1px solid var(--primary);
            border-radius: 38px;
            padding: 1rem 3.75rem;
            background-color: rgb(0, 90, 235);
            background-color: var(--primary);
            color: #fff;
            color: var(--white);
        }

        .not-found-home-button:hover {
            margin-top: 3.75rem;
            border: 1px solid rgb(0, 90, 235);
            border: 1px solid var(--primary);
            border-radius: 38px;
            padding: 1rem 3.75rem;
            background-color: transparent;
            color: rgb(0, 90, 235);
            color: var(--primary);
        }
    `]
})
export class PageNotFoundComponent {
    constructor(public _location: Location, private router: Router, private share: SharedService) {
    }

    home() {
        this.router.navigate([this.share.defaultPage]);
    }
}
