export interface Theme {
  name: string;
  properties: any;
}

export const light: Theme = {
  name: 'light',
  properties: {
    '--themeType': '#fff',
    '--lightWhite': '#e3e3e3',
    '--moreLightWhite': '#e8e8e8',
    '--fair': '#f2f2f2',
    '--fairLight': '#f7f7f7',
    '--sideBarIcon': '#005BEA',
    '--sideBarActive': '#E2EDFF',
    '--sideBarBg': '#fff',
    '--legendText': '#707070',
    '--secondaryLightWhite': '#fafafa',
    '--grey': 'rgba(111,111,111,1)',
    '--greyLight': 'rgba(111,111,111,0.1)',
    '--progressBg': '#f7f7f7',
    '--tableBg': '#f6f6f6',
    '--darkGray': 'rgba(39,39,39,0.5)'
  }
};

export const dark: Theme = {
  name: 'dark',
  properties: {
    '--themeType': '#222222',
    '--fair': '#171717',
    '--lightWhite': '#dbdbdb',
    '--fairLight': '#292929',
    '--sideBarIcon': '#fff',
    '--sideBarActive': '#272727',
    '--sideBarBg': '#171717',
    '--gray': '#2d2d2d',
    '--legendText': '#e3e3e3',
    '--grey': 'rgba(159,159,159,1)',
    '--greyLight': 'rgba(159,159,159,0.1)',
    '--progressBg': '#6d6d6d',
    '--tableBg': '#6f6f6f'
  }
};
