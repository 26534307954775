import { environment } from 'src/environments/environment';

const envir = environment.api + 'api/',
    //file = 'https://valueat.valuefy.com/ff-dev-api/' + 'asset/';
    file = environment.api + 'asset/';

export const consts = {
    LocalStore: {
        token: 'vt.token',
        userID: 'userID',
        amcId: 'amc_id',
        userName: 'userName',
        clientName: 'clientName',
        expiration_date: 'expiration_date',
        is_light_theme: 'is_light_theme',
        amc_name: 'amc_name',
        lstSelectedFltr: 'lstSelectedFltr',
        asOnDate: 'as_on_date',
        lstPageMst: 'lstPageMst',
        currentPageId: 'currentPageId',
        defaultPage: 'defaultPage',
        role: 'role',
        isTrialUser: 'isTrialUser',
        eagleEyeBalance: 'eagleEyeBalance',
        reportBalance: 'reportBalance',
    },
    api: {
        user: {
            auth: envir + 'User/authenticate/',
            logout: envir + 'User/logout/',
            updateTheme: envir + 'User/update_theme/',
            changePassword: envir + 'User/ChangePassword'
        },
        register: {
            checkEmailExists: envir + 'user/is_user_exist',
            countryData: envir + 'common/get_country_mst',
            register: envir + 'user/register',
            verifyUser: envir + 'user/trialreg',
            registerPreApprovedUser: envir + 'user/registerPreAppUser'
        },
        profile: {
            dashboard: envir + 'Profile/get_amc/',
            twit: envir + 'Profile/get_twit/',
            docs: envir + 'Profile/get_doc/',
            media: envir + 'Profile/get_media/'
        },
        amc: {
            filterData: envir + 'AMC/get_amc_fltr_data/',
            filterMst: envir + 'AMC/get_filter_mst/',
        },
        amcAnalytics: {
            amcMain: envir + 'Analysis/getTopParams/',
            amcAlloc: envir + 'Analysis/getAssetAlloc/',
            amcFund: envir + 'Analysis/getamcfunds/',
            amcShareClass: envir + 'Analysis/getFundShareDetails/',
            adminXl: envir + 'Analysis/admin_xl/',
        },
        mktIntel: {
            overvwMngr: envir + 'MktOvervw/get_mkt_int_overvw_mngr/',
            overvwHolder: envir + 'MktOvervw/get_mkt_int_overvw_hold/',
            rest: envir + 'MktOvervw/get_mkt_overvw_rst/',
            t5Fnd: envir + 'MktOvervw/get_mkt_overvw_t5_fnd/',
            lstAsstMngr: envir + 'MktOvervw/get_asst_mngr/',
            lstholder: envir + 'MktOvervw/get_holder/',
            filterMst: envir + 'MktOvervw/get_fltr_mst_mkt_intel/',

            indvlHold: envir + 'MktAsetMngr/get_mkt_individual_holder/',
            asstMgr: envir + 'MktAsetMngr/get_mkt_asst_mgr/',
            asstMgrFnd: envir + 'MktAsetMngr/get_mkt_asst_mgr_fnd/',

            indvlMgr: envir + 'MktHolder/get_mkt_individual_mgr/',
            holder: envir + 'MktHolder/get_mkt_holder/',
            holderFnd: envir + 'MktHolder/get_mkt_holder_fnd/',
            mktXl: envir + 'MktOvervw/download_mkt_int_xl/',
        },
        fundDashboard: {
            targetData: envir + 'fund/getTargetMst/',
            mainFundData: envir + 'fund/getFSdata/',
            mstData: envir + 'fund/getfundmst/',
            scData: envir + 'fund/specificShareClass/',
            adminXl: envir + 'fund/admin_xl/',
        },
        competitiveAnalytics: {
            getAmcWiseAnalystic: envir + 'CompetitiveAnalysis/getAmcDetail/',
            getAmcAnalysticAssetWise: envir + 'CompetitiveAnalysis/getAmcDetailAssetIdWise/',
            adminXl: envir + 'CompetitiveAnalysis/admin_xl/',
            getVfm: envir + 'CompetitiveAnalysis/get_vfm/',
        },
        common: {
            autoComplete: envir + 'Common/auto_Complete/',
            table2Xl: envir + 'Common/table_2_xl/',
            logUserActivity: envir + 'Common/log_user_activity/',
            logError: envir + 'log_error',
            pageMst: envir + 'Common/get_page_master/',
            authUserPage: envir + 'Common/auth_user_page/',
            setColumnState: envir + 'Common/setModuleColumnState/',
            getColumnState: envir + 'Common/getModuleColumnState/',
        },
        fundCompetitive: {
            pageLoad: envir + 'FundCompetitive/get_page_load/',
            rest: envir + 'FundCompetitive/get_rest/',
            peer: envir + 'FundCompetitive/get_peer/',
            peerXL: envir + 'FundCompetitive/dowload_peer_excel/',
            summary: envir + 'FundCompetitive/get_summary/',
            adminXl: envir + 'FundCompetitive/admin_xl/',
            peerTrgtMap: envir + 'FundCompetitive/get_peer_target_map/',
        },
        eagleEye: {
            pageLoad: envir + 'EagleEye/get_eagle_eye/',
            eeFilters: envir + 'EagleEye/getEEFilters/',
            eagleEyeFunds: envir + 'EagleEye/eagleEyeFunds/',
        },
        /*emt: {
            pageLoad: envir + 'emt/',
            eeFilters: envir + 'EagleEye/getEEFilters/',
            eagleEyeFunds: envir + 'EagleEye/eagleEyeFunds/',
        },*/
        corporateAction: {
            getUserPriority: envir + 'corporateActions/getUsrPrior/',
            updateUserPriority: envir + 'corporateActions/updUsrPriorities/',
            uploadXLData: envir + 'CorporateActions/upload_xl_data/',
            getCorporateActionData: envir + 'CorporateActions/get_corporate_action_data/',
            getAdvisorUserClients: envir + 'CorporateActions/get_advisor_user_clients/',
            getLstOfISIN: envir + 'CorporateActions/get_lst_of_ISIN/',
            getCorporateDefinitionMst: envir + 'CorporateActions/get_corporate_definition_mst/',
            insCorpActnConfig: envir + 'CorporateActions/ins_corp_actn_config/'
        },
        portfolioReport: {
            //client
            uploadClientXl: envir + 'PortRptClient/upload_client_xl/',
            bestClientExePdf: envir + 'PortRptClient/best_exe_xl/',
            vfmClientPdf: envir + 'PortRptClient/vfm_xl/',

            //common
            xlLst: envir + 'PortRptCommon/xl_lst/',
            xlData: envir + 'PortRptCommon/xl_data/',
            pdfExist: envir + 'PortRptCommon/pdf_exist/',
            xlExist: envir + 'PortRptCommon/xl_exist/',
            uploadPdf: envir + 'PortRptCommon/insert_pdf/',
            pdfLst: envir + 'PortRptCommon/pdf_list/',
            deletePdf: envir + 'PortRptCommon/delete_pdf/',
            uploadRebateXl: envir + 'PortRptCommon/upload_rebate_xl/',
            uploadClientDetailXl: envir + 'PortRptCommon/upload_client_det_xl/',

            //Recommend
            uploadRecommendXl: envir + 'PortRptRecommend/upload_recommend_xl/',
            fltrRecommendPdf: envir + 'PortRptRecommend/fltr_pdf/',
            bestRecommendExePdf: envir + 'PortRptRecommend/best_exe_pdf/',
        }
    },
    files: {
        banner: file + 'image/banner/',
        logo: file + 'image/logo/',
        newArticle: file + 'image/news-article/',
        docs: file + 'documents/',
        createdDoc: environment.api + 'wwwroot/',
    },
    newApiDomains: [
        'http://13.38.250.134'
    ]
};
