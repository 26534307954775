export class Cnvt {
    //to fix with error handling
    public static toFix(value: number, isMul100?: boolean, isErr?: any): any {
        let res = value;
        try {
            if (!value) {
                isErr = (!isErr) ? 0 : isErr;
                throw isErr;
            }
            if (isMul100) {
                res = value * 100;
            }
            res = Number(res.toFixed(2));
        } catch (ex) {
            res = isErr;
        }
        return res;
    }

    // get unique value from the object
    public static unique(jsonObj: any[]) {
        const res = new Map();
        jsonObj.forEach((item: any) => {
            res.set(JSON.stringify(item), item);
        });
        return [...res.values()];
    }

    //sort number or string
    public static sort(obj: any[], sortByKeyName: string, isAsc?: boolean) {
        if (!obj) {
            return [];
        }
        obj.sort((a: any, b: any) => {
            if (isAsc) {
                return a['' + sortByKeyName + ''] - b['' + sortByKeyName + ''];
            } else {
                return b['' + sortByKeyName + ''] - a['' + sortByKeyName + ''];
            }
        });
        return obj;
    }

    //sort dateTime
    public static sortDt(obj: any[], sortByKeyName: any, isAsc?: boolean) {
        return obj.sort((a, b) => {
            const c = new Date(a['' + sortByKeyName + '']).valueOf();
            const d = new Date(b['' + sortByKeyName + '']).valueOf();
            if (isAsc) {
                return c - d;
            } else {
                return d - c;
            }
        });
    }

    public static numberWithCommas(v: number) {
        const parts = v.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
    }

    public static getMonth(date: string, format?: string) {
        let result;
        const monthString = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const fullMonthString = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        if (format === undefined) {
            format = 'MMM';
        }
        switch (format) {
            case 'MMM':
                result = monthString[new Date(date).getMonth()];
                break;
            case 'MMMM':
                result = fullMonthString[new Date(date).getMonth()];
                break;
            default:
                result = new Date(date).getMonth() + 1;
                result = result.toString().length === 1 ? '0' + result : result;
                break;
        }
        return result;
    }

    public static getMmmYY(date: string) {
        return this.getMonth(date) + '-' + new Date(date).getFullYear().toString().substr(2);
    }

    public static formatDate(date: string, format: string = 'dd MMM yyyy') {
        let result, dd = '';
        switch (format) {
            case 'dd MMM yyyy':
                dd = new Date(date).getDate().toString();
                dd = dd.length === 1 ? '0' + dd : dd;
                result = dd + ' ' + this.getMonth(date) + ' ' + new Date(date).getFullYear();
                break;
            case 'DD-Mmm-YY':
                dd = new Date(date).getDate().toString();
                dd = dd.length === 1 ? '0' + dd : dd;
                result = dd + '-' + this.getMonth(date) + '-' + new Date(date).getFullYear();
                break;
            case 'yyyy-mm-dd':
                dd = new Date(date).getDate().toString();
                dd = dd.length === 1 ? '0' + dd : dd;
                result = new Date(date).getFullYear() + '-' + this.getMonth(date, '') + '-' + dd;
                break;
            case 'MMMM dd, YYYY':
                dd = new Date(date).getDate().toString();
                dd = dd.length === 1 ? '0' + dd : dd;
                result = this.getMonth(date, 'MMMM') + ' ' + dd + ', ' + new Date(date).getFullYear();
                break;
            default:
                result = date;
                break;
        }
        return result;
    }

    public static percent(value: any) {
        let result = '';
        try {
            result = (value * 100).toFixed(2) + '%';
        } catch (ex) {
            result = '';
        }
        return result;
    }

    public static objectToDecimal(s: any, valueifNull: any = 0) {
        try {
            if ((s != null) && (s.toString().trim() !== '') && (s !== undefined)) {
                if (s.toString() === 'NaN') {
                    return valueifNull;
                }
                s = s.toString().replaceAll(',','');
                return parseFloat(s);
            } else {
                return valueifNull;
            }
        } catch (err) {
            return valueifNull;
        }
    }

    public static objectToString(obj: any, valueifNull: any = '') {
        try {
            if (obj !== null && obj !== '' && obj !== undefined) {
                obj = obj.toString();
                return obj.toString();
            } else {
                return valueifNull;
            }
        } catch (err) {
            return valueifNull;
        }
    }

    public static IsObjectNullOrEmpty(obj: any) {
        try {
            if ((obj !== undefined) && (obj != null) && (obj.toString().trim() !== '')) {
                return false;
            } else {
                return true;
            }
        } catch (err) {
            return true;
        }
    }

    public static objectToInt(s: any, valueifNull: any = 0) {
        try {
            if ((s != null) && (s.toString().trim() !== '') && (s !== undefined)) {
                // tslint:disable-next-line: radix
                if (parseInt(s).toString() === 'NaN') {
                    return valueifNull;
                }
                // tslint:disable-next-line: radix
                return parseInt(s);
            } else {
                return valueifNull;
            }
        } catch (err) {
            return valueifNull;
        }
    }

    public static JSONParse(s: any, valueifNull: any = []) {
        try {
            return JSON.parse(s);
        } catch {
            return valueifNull;
        }
    }

    public static isNumber(s: any) {
        let res = false;
        if (this.objectToInt(s, '-') !== '-') {
            res = true;
        } else {
            res = false;
        }
        return res;
    }

    public static copyObj(s: any) {
        return JSON.parse(JSON.stringify(s));
    }

    public static getDayName(date: string, format?: string) {
        let result;
        const daynameString = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        result = daynameString[new Date(date).getDay()];
        return result;
    }

    public static sort_v1(obj: any[], sortByKeyName: string, isAsc?: boolean) {
        if (!obj) {
            return [];
        }
        if (isAsc) {
            obj.sort((a, b) => (a[sortByKeyName] < b[sortByKeyName] ? -1 : 1));
        } else {
            obj.sort((a, b) => (a[sortByKeyName] > b[sortByKeyName] ? -1 : 1));
        }
        return obj;
    }

    public static isDate(data: string) {
        let res = false;
        try {
            data = data.replace('T00:00:00', '');
            const tmpArr = data.split('-');
            if (tmpArr.length === 3 && Cnvt.isNumber(tmpArr[0]) && Cnvt.isNumber(tmpArr[1]) && Cnvt.isNumber(tmpArr[2])) {
                res = true;
            }
        } catch (ex) {
            res = false;
        }
        return res;
    }

    public static appendPercent(s: any, valueifNull: any = '-') {
        let res = valueifNull;
        try {
            res = !this.IsObjectNullOrEmpty(s) ? parseFloat(s.toFixed(2)) + '%' : valueifNull;
        } catch {
            res = valueifNull
        }
        return res;
    }

    public static isErrInApi(res: any) {
        let isErrInApi = false;
        try {
            if (res.statusCode !== 200) {
                //console.log(res);
                isErrInApi = true;
            }
            if (!isErrInApi) {
                let tmp = JSON.parse(res.data)[0];
                if (tmp.status && tmp.status === 'error') {
                    //console.log(tmp.data);
                    isErrInApi = true;
                }
            }
        } catch {
            isErrInApi = false;
        }
        return isErrInApi;
    }

    public static isFinite(s: any) {
        let res = false;
        try {
            if (s.isFinite(s)) {
                res = true;
            } else {
                res = false;
            }
        } catch {
            res = false;
        }
        return res;
    }

    public static removeComma(value: any) {
        const numberVal = value.split(',').join('');
        return numberVal;
    }

    public static isKeywordFound(data: any, keyword: any) {
        let res = false, objKeys = [] as any;
        keyword.toUpperCase().split(' ').forEach((vk: any) => {
            if (vk !== '') {
                objKeys.push({
                    key: vk.toUpperCase(),
                    isFound: false,
                });
            }
        });
        objKeys.forEach((v: any) => {
            if ((data.toUpperCase().indexOf(v.key) >= 0)) {
                v.isFound = true;
            }
        });
        objKeys = objKeys.filter((v: any) => {
            return v.isFound === false;
        });
        if (objKeys.length > 0) {
            res = false;
        } else {
            res = true;
        }
        return res;
    }
}
