import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Cnvt } from 'src/app/core/utils/cnvt';
import { consts } from 'src/app/core/utils/const';
import { DDLSetngDTO } from '../../../common-model';
import { SharedService } from '../../../shared.service';
import { TestApiService } from 'src/app/modules/corporate-action/test-api.service';

@Component({
  selector: 'app-ddl',
  templateUrl: './ddl.component.html',
  styles: [`
  .m-r-15px{margin-right: -15px;}
  .fltr-icon{width: 0.75rem;height: 0.75rem;}
  .custom-ddl {
    color: #595959 !important;
    font-size: 14px;
    font-weight: 500;
  }
  `]
})
export class DdlComponent implements OnInit, OnChanges {
  @Input() ddlSetting!: DDLSetngDTO; @Input() isStaticDataLoaded = false;
  @Output() emitEvent = new EventEmitter();
  @ViewChild('ddl', { static: false }) ddl!: ElementRef;
  @ViewChild('autoCompleteElement', { static: false }) autoCompleteElement!: ElementRef;
  isEnable = false; pageNum = 0; isScrollApiLoaded = true; searchValue = '';
  tmpData: any = []; isScrollToStartPosition = false; isSelectAllSelected = false; isFrmSingleSelect = false;
  selectedCnt = 0;
  private specialKeys: Array<string> = ['ArrowUp', 'ArrowDown', 'Enter'];
  constructor(private share: SharedService, renderer: Renderer2, public TestApi: TestApiService) {
    renderer.listen('window', 'click', (e: Event) => {
      if (this.ddl.nativeElement && !this.ddl.nativeElement.contains(e.target)) {
        this.isEnable = false;
        if (this.ddlSetting.isCloseNotify) {
          this.emitEvent.next({ operation: 'ddlClose' });
        }
      }
    });
  }

  ngOnInit() {}

  ngOnChanges() {


    if (this.isStaticDataLoaded) {
      this.getData();
      this.isScrollApiLoaded = true;
    }
    if (this.isStaticDataLoaded && this.ddlSetting.isExternalToggle) {
      this.toggleDDL();
    }
    if (this.isStaticDataLoaded && this.ddlSetting.isSelectedCount) {
      this.setSelectedCount();
    }
  }
 /*getData() {
    {//validation
      if (!this.ddlSetting) {
        return;
      }
      if (this.ddlSetting.isDisable) {
        return;
      }
      if (!this.isEnable) {
        return;
      }
      if (this.ddlSetting.isAutoCompleteRequired) {
        setTimeout(() => {
          this.autoCompleteElement.nativeElement.focus();
        }, 1);
      }
    }

    {//static data
      if (!this.ddlSetting.isDynamic) {
        if (this.ddlSetting.data && this.ddlSetting.data.length === 0) {
          this.emitEvent.next({ operation: 'noStaticData', data: this.tmpData });
          return;
        }
        this.tmpData = JSON.parse(JSON.stringify(this.ddlSetting.data));
        if (!this.ddlSetting.isStaticPagination) {
          this.tmpData.map((i: any) => i.isSearch = true);
          this.ddlSetting.totalRecord = this.ddlSetting.data.length;
        }
        //this.chkAnySelected();
        this.getIsSelectAllSelected();
        this.isScrollApiLoaded = true;
        return;
      }
    }

    {//dynamic data 
      if (this.ddlSetting.isDynamic) {
        if (this.ddlSetting.totalRecord && this.tmpData.length !== 0 && this.tmpData.length >= this.ddlSetting.totalRecord) {
          return;
        }
        this.isEnable = true; this.isScrollApiLoaded = false;
        const req = {
          moduleName: this.ddlSetting.moduleName,
          searchText: this.searchValue,
          operType: this.ddlSetting.operType,
          pageSize: this.ddlSetting.pageSize,
          pageNum: this.pageNum
        };
        this.share.post(consts.api.common.autoComplete, req).subscribe((res: any) => {
          try {
            const tmp = JSON.parse(res.data);
            this.ddlSetting.totalRecord = tmp.total_record[0].total_record;
            if (this.ddlSetting.isMuliSelect) {
              tmp.data.forEach((v: any) => {
                this.tmpData.push({
                  code: v.code,
                  value: v.value,
                  isSelected: false,
                  isSearch: true,
                });
              });
            } else {
              tmp.data.forEach((v: any) => {
                this.tmpData.push({
                  code: v.code,
                  value: v.value,
                  isSearch: true,
                });
              });
            }

            {// check the selected check box
              if (this.ddlSetting.isMuliSelect) {
                this.chkSelectedDdl();
              }
            }
          } finally {
            setTimeout(() => {
              this.isScrollApiLoaded = true;
            }, 10);
          }
        });
      }
    }
  }*/
 
  getData() {
    {//validation
      if (!this.ddlSetting) {
        return;
      }
      if (this.ddlSetting.isDisable) {
        return;
      }
      /*if (!this.isEnable) {
        return;
      }*/
      if (this.ddlSetting.isAutoCompleteRequired) {
        setTimeout(() => {
          this.autoCompleteElement.nativeElement.focus();
        }, 1);
      }
    }
    {//static data

      if (!this.ddlSetting.isDynamic) {
        if (this.ddlSetting.data && this.ddlSetting.data.length === 0) {
          this.emitEvent.next({ operation: 'noStaticData', data: this.tmpData });
          return;
        }
        this.tmpData = this.ddlSetting.data;
        if (!this.ddlSetting.isStaticPagination) {
          this.tmpData.map((i: any) => i.isSearch = true);
          this.ddlSetting.totalRecord = this.ddlSetting.data.length;
        }
        //this.chkAnySelected();
        this.getIsSelectAllSelected();
        this.isScrollApiLoaded = true;
        return;
      }


    }

    {//dynamic data 
      if (this.ddlSetting.isDynamic) {
        if (this.ddlSetting.totalRecord && this.tmpData.length !== 0 && this.tmpData.length >= this.ddlSetting.totalRecord) {
          return;
        }
        this.isEnable = true; this.isScrollApiLoaded = false;
        const req = {
          moduleName: this.ddlSetting.moduleName,
          searchText: this.searchValue,
          operType: this.ddlSetting.operType,
          pageSize: this.ddlSetting.pageSize,
          pageNum: this.pageNum
        };
        const startTime = ""
        this.TestApi.getAdvisorUser(startTime).subscribe((res: any) => {
          try {
            const tmp = res.info;
            this.ddlSetting.totalRecord = tmp.total_record[0].total_record;
            if (this.ddlSetting.isMuliSelect) {
              tmp.data.forEach((v: any) => {
                this.tmpData.push({
                  code: v.code,
                  value: v.value,
                  isSelected: false,
                  isSearch: true,
                });
              });
            } else {
              tmp.data.forEach((v: any) => {
                this.tmpData.push({
                  code: v.code,
                  value: v.value,
                  isSearch: true,
                });
              });
            }

            {// check the selected check box
              if (this.ddlSetting.isMuliSelect) {
                this.chkSelectedDdl();
              }
            }
          } finally {
            setTimeout(() => {
              this.isScrollApiLoaded = true;
            }, 10);
          }
        });
      }
    }
  }

  private chkSelectedDdl() {
    if (this.tmpData) {
      this.tmpData.forEach((v: any) => {
        v.isSelected = this.getIsSelected(v.code);
      });
    }
  }

  private getIsSelected(code: string) {
    let res = false;
    if (this.ddlSetting.data) {
      for (const v of this.ddlSetting.data) {
        if (v.code === code && v.isSelected) {
          res = v.isSelected;
          break;
        }
      }
    }
    return res;
  }

  onSelect(data?: any) {
    this.isFrmSingleSelect = true;
    if (!this.ddlSetting.isDynamic) {
      this.getIsSelectAllSelected();
      this.isFrmSingleSelect = false;
    }
    if (this.ddlSetting.selectedCode !== undefined) {
      this.ddlSetting.selectedCode = data.code
    }
    if (!this.ddlSetting.isMuliSelect) {
      this.ddlSetting.name = data.value; this.isEnable = !this.isEnable;
    }
    this.emitEvent.next({ operation: 'singleSelect', data: this.tmpData });
  }

  onScroll() {
    if (this.ddlSetting.totalRecord && this.tmpData.length !== 0 && this.tmpData.length >= this.ddlSetting.totalRecord) {
      return;
    }
    if (!this.ddlSetting.isDynamic) {
      if (this.ddlSetting.isStaticPagination && this.isScrollApiLoaded) {
        this.emitEvent.next({ operation: 'endOfScroll', data: this.tmpData });
      }
      return;
    }
    this.pageNum = this.pageNum + 1;
    this.getData();
  }
  filteredData:any = [];
  onKeyPress(event: any) {
    if (this.specialKeys.includes(event.code)) {
      return;
    }
    const inp = String.fromCharCode(event.keyCode);
    // if (!/[a-zA-Z0-9\b\0 ]/.test(inp)) {
    //   event.preventDefault(); return false;
    // }
    if (this.searchValue.length < 3 && this.searchValue !== '') {
      return;
    }
    if (!this.ddlSetting.isDynamic) {
      if (this.ddlSetting.isStaticPagination && this.isScrollApiLoaded) {
        this.emitEvent.next({ operation: 'searching', data: this.tmpData, searchValue: this.searchValue });
        this.filteredData = this.tmpData.filter((item: { value: string; }) => {
           item.value.toLowerCase().startsWith(this.searchValue.toLowerCase())});
        return;
      }
      if (this.searchValue === '') {
        this.tmpData = JSON.parse(JSON.stringify(this.ddlSetting.data));
      }
      if (this.tmpData) {
        this.tmpData.forEach((v: any) => {
          v.isSearch = Cnvt.isKeywordFound(v.value, this.searchValue);
        });
      }
      return;
    }
    this.pageNum = 0; this.tmpData = []; this.getData();
  }

  toggleDDL() {
    if (this.ddlSetting.isDisable) {
      return;
    }
    this.searchValue = '';
    setTimeout(() => {
      this.isScrollToStartPosition = true;
    }, 2);
    setTimeout(() => {
      this.isScrollToStartPosition = false;
    }, 4);
    if (!this.ddlSetting.isDynamic && this.tmpData && this.tmpData.length === 0) {
      this.isScrollApiLoaded = false;
    }
    this.isEnable = !this.isEnable; this.tmpData = []; this.pageNum = 0;
    this.emitEvent.next({ operation: 'toggleDDL', data: this.tmpData, searchValue: this.searchValue });
    this.getData();
  }

  private chkAnySelected() {
    let res = false;
    for (const v of this.tmpData) {
      if (v.isSelected) {
        res = true;
        break;
      }
    }
    this.ddlSetting.isAnyMultiSelected = res;
  }

  onDdlClear() {
    this.isSelectAllSelected = false;
    this.tmpData.forEach((v: any) => { v.isSelected = false; v.isSearch = true; }); this.searchValue = '';
    this.emitEvent.next({ operation: 'clearAll', data: this.tmpData });
  }

  onDdlApply() {
    this.chkAnySelected();
    this.emitEvent.next({ operation: 'apply', data: this.tmpData });
    this.setSelectedCount();
  }

  private getIsSelectAllSelected() {
    if (this.ddlSetting.isStaticPagination && !this.isFrmSingleSelect) {
      return;
    }
    if (!this.ddlSetting.isMuliSelect && !this.ddlSetting.isSelectAllRequired) {
      return;
    }
    const selectedData = this.tmpData.filter((v: any) => {
      return v.isSelected;
    });
    if (selectedData.length === this.tmpData.length && this.ddlSetting.totalRecord === this.tmpData.length) {
      this.isSelectAllSelected = true;
    } else {
      this.isSelectAllSelected = false;
    }
  }

  onSelectAll(e: any) {
      this.tmpData.forEach((v: any) => { v.isSelected = e.currentTarget.checked; });
      this.emitEvent.next({ operation: 'selectAll', isChecked: e.currentTarget.checked });
  }

  private setSelectedCount() {
    if (this.ddlSetting.isSelectedCount) {
      const tmp = this.ddlSetting.data.filter((v: any) => {
        return v.isSelected === true;
      });
      this.selectedCnt = tmp.length;
    }
  }

}
