import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, merge } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PortfolioReportService {

  rutApiPro = "https://api.fundsfairway.com";
  rutApiTest = "http://13.39.148.219";
  rutUse = this.rutApiPro;

  isClient = true; isDataLoaded = false; isAnyDataAvaiable = false; lsClientData = [] as any;
  lstRecommendData = [] as any; recommendPdfFltr = {} as any; moduleName = 'PORTFOLIOS AND REPORTS';
  isTrialOver = false;
  private emitXlUploaded = new Subject<any>(); public emitXlUploaded$ = this.emitXlUploaded.asObservable();

 
  constructor(private http:HttpClient) { }



  onEmitXlUploaded() {
    return this.emitXlUploaded.next();
  }

  uploadClientPortfolio(req:any){
    return this.http.post(`${this.rutUse}/api/reports/generate_report/`,req);
  }


  getTableReport(){
    var req = 1
    return this.http.post(`${this.rutUse}/api/reports/all_files_metadata/`,req);
  }

  getFile(fileId: string, fileType: string){
    var req = 1

    return this.http.post(`${this.rutUse}/api/reports/get_file/?file_id=${fileId}&file_type=${fileType}`,req);
  }

  private notificarCambioSubject: Subject<boolean | null> = new Subject<boolean | null>();
  private validate: boolean = false;

  notificarCambio$(): Observable<boolean | null> {
      return this.notificarCambioSubject;
    
  }

  notificarCambio(): void {
    this.validate = true;
    // Cambiar a BehaviorSubject después de la notificación
    this.notificarCambioSubject.next(true);
  }

}