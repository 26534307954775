import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DDLSetngDTO } from '../shared/common-model';

@Injectable({
  providedIn: 'root'
})
export class CorporateActionService {
  public corporateActionData: any = []; advisorAllData: any = []; calendarTableData: any = [];
  public isHideXlLoaded = false; isAdvisorApiLoaded = false;
  public emitDefApply = new Subject<any>(); public emitDefApply$ = this.emitDefApply.asObservable();
  constructor() {
    this.corporateActionData.length = 0; this.advisorAllData.length = 0; this.calendarTableData.length = 0;
    this.calendarBck2Data.length = 0;
  }
  calendarBck2Data: any = [];
  advisorChangeData = {
    umbrella: [] as any,
    fund_name: [] as any,
    share_class_name: [] as any,
    client_name: [] as any,
    all: [] as any,
    scope_affected: [] as any
  }; selectedActionType = 'Dividends'; selectActionTypeDefination = "Dividends are the distribution of some of a company's earnings to a class of its shareholders, as determined by the company's board of directors.";

  calendarFilterData = {
    amc_name: [] as any,
    fund_name: [] as any,

    client_name: [] as any,
    umbrella: [] as any,
    ISIN: [] as any,
    scope_affected: [] as any,
  };
  
  definitionData = [] as any;
  configureEventDdl: DDLSetngDTO = { 
    moduleName: 'configureEvent',
    name: 'Effective Date',
    defaultValue: 'Effective Date',
    isDisable: false,
    data: [{
      code: 1,
      value: 'Announcement Date',
    }, {
      code: 2,
      value: 'Effective Date',
    }, {
      code: 3,
      value: 'Both',
    }] as any,
  };

  public getConfigureEvent(id: number): any {
    if (this.configureEventDdl.data) {
      return this.configureEventDdl.data.filter((v: any) => {
        return v.code === id;
      })[0].value;
    }
  }
}
