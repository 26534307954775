import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  constructor(private http: HttpClient) { }

  rutApiPro = "https://api.fundsfairway.com";
  rutApiTest = "http://13.39.148.219";
  rutUse = this.rutApiPro;

  private sharedData: Array<any> = [];


  getComponentsAccess(){


      return this.http.get(`${this.rutUse}/api/users/user_groups/`)
      
  }

  
  

  setData(data: any) {
    this.sharedData = data;
  }

  getData() {
    return   of(this.sharedData);
  }
}