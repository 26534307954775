import { Component } from '@angular/core';

@Component({
  selector: 'app-loader',
  template: `<div class="loader-container"><div class="loader"><div></div><div></div></div></div>`,
  styles: [`
  .loader-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.2);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .loader div {
    position: absolute;
    border: 4px solid rgb(0, 90, 235);
    border: 4px solid var(--primary);
    opacity: 1;
    border-radius: 50%;
    animation: loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    color: blue;
  }
  .loader div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes loader {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }`]
})
export class WidgetLoaderComponent {
}
