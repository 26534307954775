import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mul100'
})
export class Mul100Pipe implements PipeTransform {

  transform(value: any, percent?: boolean, sign?: boolean) {
    let res = '', data = 0;
    if (value === undefined || value === null || value === '') {
        return res;
    }
    data = Number((value * 100).toFixed(2));
    res = data.toString();
    if (sign) {
        const tmp = (data <= 0) ? '' : '+';
        res = tmp + res;
    }
    if (percent) {
        res = res + '%';
    }
    return res;
}

}
