import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zeroDecimalBlankPipe'
})
export class ZeroDecimalBlankPipePipe implements PipeTransform {

  transform(value: any): any {
    let res = '', data = 0;
    if (value === undefined || value === null || value === '') {
        return res;
    }
    data = value.toFixed(2);
    data.toString() !== '0.00' ? res = data.toString() : res = '';

    return res;
  }

}
