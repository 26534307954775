import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appDecimalNumber]'
})
export class DecimalNumberDirective {
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const value: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [value.slice(0, position), event.key === 'Decimal' ? '.' : event.key, value.slice(position)].join('');
    if (next && !String(next).match(this.regex) && event) {
      event.preventDefault();
    }
  }
}
