import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './core/utils/auth/auth.service';
import { LayoutService } from './modules/shared/component/layout/layout.service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PruebaEmtGuard implements CanActivate {

  constructor(
    private authService: AuthService, 
    private router: Router,
    private serviceLayout: LayoutService
  ) {}


   titleToRoutesMap: { [title: string]: string[] } = {
    'corporate actions': ['/corporate-table', '/corporate-action', '/corporate-calendar'],
    'assessment of value': ['/assessment-of-value'],
    'portfolio search': ['/portfolio-search'],
    'eagle eye': ['/eagle-eye','/eagle-eye/Appropriateness','/eagle-eye/InvestorsProfile','/eagle-eye/ProductProfile','/eagle-eye/search'],
    'reports': ['/portfolio-report/client'],
    'amcDashboard': ['/amcdashboard'],
    'fundDashboard': ['/fundDashboard'],
    'amcAnalytics': ['/amcAnalytics'],
    'value for money': ['/competitiveAnalysis/amc', '/value-for-money/funds', '/competitiveAnalysis/funds/:id'],
    'market-inteligence': ['/market-intelligence/overview', '/market-intelligence/asset-manager' ,'/market-intelligence/holder'],
    'FCA permissions':['/FCA-permissions']
    // Añade más títulos y rutas según sea necesario
  };
  async access(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.serviceLayout.getData().subscribe((data: any) => {
        const routes: string[] = [];
        try {
          if (data[0] === "AdminApp") {
            // Si el dato es "AdminApp", agregar todas las rutas
            for (const title in this.titleToRoutesMap) {
              const associatedRoutes = this.titleToRoutesMap[title];
              routes.push.apply(routes, associatedRoutes);
            }
          } else if (Array.isArray(data)) {
            // Si el dato es un array de títulos, agregar rutas asociadas según los títulos
            data.forEach((title: any) => {
              if (title in this.titleToRoutesMap) {
                const associatedRoutes = this.titleToRoutesMap[title];
                routes.push.apply(routes, associatedRoutes);
              }
            });
          }
          
          // Agregar rutas adicionales comunes
          routes.push('/profile');
          /*routes.push('/competitiveAnalysis/funds');
          routes.push('/competitiveAnalysis/amc');
          routes.push('/competitiveAnalysis/:id');*/

          resolve(routes);
        } catch (error) {
          reject(error);
        }
      }, error => {
        reject(error);
      });
    });
  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    try {
      const allowedRoutes = await this.access();
      const currentRoute = state.url;

      // Verificar si el usuario está autenticado
      const isLoggedIn = await this.authService.isLoggedIn.pipe(take(1)).toPromise();

      // Si el usuario está autenticado y la ruta actual está permitida, permitir el acceso
      if (allowedRoutes.includes(currentRoute) && isLoggedIn) {
        return true;
      } else {
        // Si el usuario no está autenticado, redirigirlo a la página de inicio de sesión
        if (!isLoggedIn) {
          this.router.navigate(['/']); // Cambia '/login' por la ruta de tu página de inicio de sesión
        } else {
          // Si el usuario está autenticado pero la ruta no está permitida, redirigir a la página de inicio
          this.router.navigate(['/profile']); // Cambia '/inicio' por la ruta que desees
        }
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}
