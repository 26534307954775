import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorServiceService {
  successMessages: { [key: string]: string[] } = {};
  errorMessages: { [key: string]: string[] } = {};
  errorEvent = new EventEmitter<{key: string, message: string[],isDeleted: boolean, id:number, color:string }>();

  constructor() { }

  setError(key: string, message: string[], color: string): void {
    this.errorMessages[key] = message;
    this.errorEvent.emit({ key, message, isDeleted:false, id:Math.random(), color:color  });
  }

  setSuccess(key: string, message: string[], color: string): void {
    this.successMessages[key] = message;
    this.errorEvent.emit({ key, message, isDeleted:false, id:Math.random(), color:color  });
  }


  getErrorEvent(): EventEmitter<{key: string, message: string[], isDeleted:boolean, id:number, color:string}> {
    return this.errorEvent;
  }

  getError(key: string): string[] {
    return this.errorMessages[key] || [];
  }

  clearError(key: string): void {
    delete this.errorMessages[key];
  }
}
