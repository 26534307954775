import { Component, OnInit } from '@angular/core';

@Component({
    template: `
  <div class="not-found-container pt-5">
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="155" viewBox="0 0 618 155" fill="none">
  <path d="M352.9 95C352.9 98.1 350.3 100.7 347.2 100.7H315C311.9 100.7 309.3 98.1 309.3 95C309.3 91.9 311.9 89.3 315 89.3H347.2C350.3 89.3 352.9 91.9 352.9 95ZM347.2 97C348.3 97 349.2 96.1 349.2 95C349.2 93.9 348.3 93 347.2 93H315C313.9 93 313 93.9 313 95C313 96.1 313.9 97 315 97H347.2Z" fill="#DEDEDE"/>
  <path d="M352.9 60.6C352.9 63.7 350.3 66.3 347.2 66.3H315C311.9 66.3 309.3 63.7 309.3 60.6C309.3 57.5 311.9 54.9 315 54.9H347.2C350.3 54.9 352.9 57.4 352.9 60.6ZM347.2 62.6C348.3 62.6 349.2 61.7 349.2 60.6C349.2 59.5 348.3 58.6 347.2 58.6H315C313.9 58.6 313 59.5 313 60.6C313 61.7 313.9 62.6 315 62.6H347.2Z" fill="#DEDEDE"/>
  <path d="M375.5 44.3V111.2C375.5 112.2 374.7 113.1 373.6 113.1H342C336.8 113.1 332.5 108.8 332.5 103.6V52C332.5 46.8 336.8 42.5 342 42.5H373.7C374.7 42.5 375.5 43.3 375.5 44.3ZM371.8 109.4V46.2H342C338.8 46.2 336.2 48.8 336.2 52V103.6C336.2 106.8 338.8 109.4 342 109.4H371.8Z" fill="#DEDEDE"/>
  <path d="M380.6 47.9C380.6 44.6 378.1 42 375 42C371.9 42 369.4 44.7 369.4 47.9V107.6C369.4 110.9 371.9 113.5 375 113.5C378.1 113.5 380.6 110.8 380.6 107.6V47.9Z" fill="#F8F8F8"/>
  <path d="M382.5 47.9V107.6C382.5 111.9 379.2 115.4 375.1 115.4C371 115.4 367.7 111.9 367.7 107.6V47.9C367.7 43.6 371 40.1 375.1 40.1C379.2 40.2 382.5 43.7 382.5 47.9ZM378.8 107.6V47.9C378.8 45.7 377.1 43.8 375.1 43.8C373 43.8 371.4 45.6 371.4 47.9V107.6C371.4 109.8 373.1 111.7 375.1 111.7C377.1 111.7 378.8 109.9 378.8 107.6Z" fill="#DEDEDE"/>
  <path d="M391.7 98.4H380.7V57.2H391.7C395 57.2 397.8 59.9 397.8 63.3V92.4C397.7 95.7 395 98.4 391.7 98.4Z" fill="#F8F8F8"/>
  <path d="M399.6 63.2V92.3C399.6 96.7 396 100.2 391.7 100.2H380.7C379.7 100.2 378.8 99.4 378.8 98.3V57.2C378.8 56.2 379.6 55.3 380.7 55.3H391.7C396 55.3 399.6 58.9 399.6 63.2ZM382.5 96.5H391.7C394 96.5 395.9 94.6 395.9 92.3V63.2C395.9 60.9 394 59 391.7 59H382.5V96.5Z" fill="#DEDEDE"/>
  <path d="M349.3 73.8V81.9C349.3 82.9 348.5 83.8 347.4 83.8H334.3C333.3 83.8 332.4 83 332.4 81.9V73.8C332.4 72.8 333.2 71.9 334.3 71.9H347.4C348.5 71.9 349.3 72.7 349.3 73.8ZM336.2 80H345.6V75.6H336.2V80Z" fill="#DEDEDE"/>
  <path d="M388.5 63.5C387.5 63.5 386.6 64.3 386.6 65.4V90.3C386.6 91.3 387.4 92.2 388.5 92.2C389.5 92.2 390.4 91.4 390.4 90.3V65.3C390.4 64.3 389.5 63.5 388.5 63.5Z" fill="#DEDEDE"/>
  <path d="M289.4 123L275 137.4C274.3 138.1 274.3 139.3 275 140C275.4 140.4 275.8 140.5 276.3 140.5C276.8 140.5 277.3 140.3 277.6 140L292 125.6C292.7 124.9 292.7 123.7 292 123C291.3 122.2 290.1 122.2 289.4 123Z" fill="#DEDEDE"/>
  <path d="M311.5 130.5C310.5 130.5 309.6 131.3 309.6 132.4V152.7C309.6 153.7 310.4 154.6 311.5 154.6C312.5 154.6 313.4 153.8 313.4 152.7V132.4C313.3 131.4 312.5 130.5 311.5 130.5Z" fill="#DEDEDE"/>
  <path d="M333.2 122.2C332.5 121.5 331.3 121.5 330.6 122.2C329.9 122.9 329.9 124.1 330.6 124.8L345 139.2C345.4 139.6 345.8 139.7 346.3 139.7C346.8 139.7 347.2 139.5 347.6 139.2C348.3 138.5 348.3 137.3 347.6 136.6L333.2 122.2Z" fill="#DEDEDE"/>
  <path d="M311.2 24C312.2 24 313.1 23.2 313.1 22.1V1.9C313.1 0.9 312.3 0 311.2 0C310.2 0 309.3 0.8 309.3 1.9V22.2C309.3 23.2 310.1 24 311.2 24Z" fill="#DEDEDE"/>
  <path d="M289.4 32.4C289.8 32.8 290.2 32.9 290.7 32.9C291.2 32.9 291.7 32.7 292 32.4C292.7 31.7 292.7 30.5 292 29.8L277.6 15.4C276.9 14.7 275.7 14.7 275 15.4C274.3 16.1 274.3 17.3 275 18L289.4 32.4Z" fill="#DEDEDE"/>
  <path d="M331.9 32.2C332.4 32.2 332.8 32 333.2 31.7L347.6 17.3C348.3 16.6 348.3 15.4 347.6 14.7C346.9 14 345.7 14 345 14.7L330.6 29C329.9 29.7 329.9 30.9 330.6 31.6C330.9 32 331.4 32.2 331.9 32.2Z" fill="#DEDEDE"/>
  <path d="M232.3 98.4H243.3V57.2H232.3C229 57.2 226.2 59.9 226.2 63.3V92.4C226.2 95.7 228.9 98.4 232.3 98.4Z" fill="#F8F8F8"/>
  <path d="M232.3 55.3H243.3C244.3 55.3 245.2 56.1 245.2 57.2V98.4C245.2 99.4 244.4 100.3 243.3 100.3H232.3C227.9 100.3 224.4 96.7 224.4 92.4V63.2C224.3 58.9 227.9 55.3 232.3 55.3ZM241.4 59H232.2C229.9 59 228 60.9 228 63.2V92.3C228 94.6 229.9 96.5 232.2 96.5H241.4V59Z" fill="#DEDEDE"/>
  <path d="M235.4 63.5C236.4 63.5 237.3 64.3 237.3 65.4V90.3C237.3 91.3 236.5 92.2 235.4 92.2C234.4 92.2 233.5 91.4 233.5 90.3V65.3C233.5 64.3 234.4 63.5 235.4 63.5Z" fill="#DEDEDE"/>
  <path d="M282.6 113.1H250.9C245.7 113.1 241.4 108.8 241.4 103.6V52C241.4 46.8 245.7 42.5 250.9 42.5H282.6C283.6 42.5 284.5 43.3 284.5 44.4V111.3C284.4 112.3 283.6 113.1 282.6 113.1ZM250.9 46.2C247.7 46.2 245.1 48.8 245.1 52V103.6C245.1 106.8 247.7 109.4 250.9 109.4H280.7V46.2H250.9Z" fill="#DEDEDE"/>
  <path d="M289.5 107.6C289.5 110.9 287 113.5 283.9 113.5C280.8 113.5 278.3 110.8 278.3 107.6V47.9C278.3 44.6 280.8 42 283.9 42C287 42 289.5 44.7 289.5 47.9V107.6Z" fill="#F8F8F8"/>
  <path d="M284 115.4C279.9 115.4 276.6 111.9 276.6 107.6V47.9C276.6 43.6 279.9 40.1 284 40.1C288.1 40.1 291.4 43.6 291.4 47.9V107.6C291.4 111.9 288.1 115.4 284 115.4ZM284 43.9C281.9 43.9 280.3 45.7 280.3 48V107.7C280.3 109.9 282 111.8 284 111.8C286.1 111.8 287.7 110 287.7 107.7V48C287.7 45.7 286 43.9 284 43.9Z" fill="#DEDEDE"/>
  <path d="M267.7 88.3H254.9C253.9 88.3 253 89.1 253 90.2C253 91.2 253.8 92.1 254.9 92.1H267.7C268.7 92.1 269.6 91.3 269.6 90.2C269.6 89.1 268.7 88.3 267.7 88.3Z" fill="#DEDEDE"/>
  <path d="M254.9 83.8H267.7C268.7 83.8 269.6 83 269.6 81.9C269.6 80.9 268.8 80 267.7 80H254.9C253.9 80 253 80.8 253 81.9C253 82.9 253.8 83.8 254.9 83.8Z" fill="#DEDEDE"/>
  <path d="M254.9 75.5H267.7C268.7 75.5 269.6 74.7 269.6 73.6C269.6 72.6 268.8 71.7 267.7 71.7H254.9C253.9 71.7 253 72.5 253 73.6C253 74.7 253.8 75.5 254.9 75.5Z" fill="#DEDEDE"/>
  <path d="M254.9 67.3H267.7C268.7 67.3 269.6 66.5 269.6 65.4C269.6 64.4 268.8 63.5 267.7 63.5H254.9C253.9 63.5 253 64.3 253 65.4C253 66.5 253.8 67.3 254.9 67.3Z" fill="#DEDEDE"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M618 79.2443C615.724 81.6019 611.274 85.1 603.647 87.3C599.008 88.6 593.742 89.3 587.912 89.3C571.737 89.3 551.927 84.3 529.107 74.5C505.535 64.2 480.647 59 455.194 59C445.289 59 435.384 59.8 425.729 61.4C410.746 63.8 399.023 67.6 391.5 70.5V85.6H391.563C398.521 82.8 410.809 78.6 426.67 76C436.011 74.5 445.603 73.7 455.194 73.7C479.832 73.7 503.843 78.7 526.725 88.5C550.36 98.7 570.922 103.9 587.786 103.9C602.401 103.9 611.947 100.033 618 95.8628V91.8589C606.952 100.037 581.392 108.296 527.352 85C486.916 67.5 450.179 68.4 426.481 72.3C410.808 74.9 398.709 79 391.562 81.8V74.2C398.897 71.3 410.746 67.5 425.98 65C434.318 63.7 444.16 62.7 455.257 62.7C476.258 62.7 501.585 66.3 528.543 77.9C568.226 95 591.547 94.3 604.086 90.8C610.849 88.8843 615.27 85.952 618 83.5021V79.2443Z" fill="#DEDEDE"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 92.9824C5.75658 98.0504 16.405 103.9 34.9752 103.9C52.3978 103.9 73.6418 98.7 98.0595 88.5C121.635 78.7 146.441 73.7 171.895 73.7C181.87 73.7 191.779 74.5 201.43 76C217.767 78.5922 230.435 82.7748 237.635 85.5748V85.6H237.7L237.635 85.5748L237.635 81.8L237.635 81.7755C230.243 78.9761 217.769 74.8924 201.624 72.3C177.142 68.5 139.187 67.6 97.347 85C34.3239 111.32 8.81294 97.3617 0 88.747V92.9824ZM0 79.4132C0.975518 80.9151 5.77962 87.4303 18.0706 90.8C31.0243 94.3 55.1181 95 96.1165 77.9C123.967 66.3 150.133 62.7 171.831 62.7C183.23 62.7 193.463 63.7 202.077 65C217.816 67.5 230.057 71.3 237.635 74.2L237.635 70.5C229.928 67.6 217.816 63.8 202.336 61.4C192.362 59.8 182.129 59 171.895 59C145.599 59 119.886 64.2 95.4688 74.5C71.8931 84.3 51.4263 89.3 34.7161 89.3C28.6926 89.3 23.2521 88.6 18.4592 87.3C6.2828 83.9 1.94332 77.4 1.61948 76.8L0.194576 74L0 74.0086V79.4132Z" fill="#DEDEDE"/>
  </svg>
       <h1 class="not-found-main-header">No Internet Connection</h1>
  </div>
   `,
    styles: [`
       .not-found-container {
           margin: 12rem auto 0;
           text-align: center;
       }
       .not-found-img {
           width: 32rem;
           height: 18rem;
       }
       .not-found-main-header {
           font-weight: 600;
           font-size: 2.25rem;
           line-height: 2.75rem;
           margin-top: 3.75rem;
       }
       .not-found-subheader {
           font-weight: 600;
           font-size: 1.75rem;
           line-height: 2rem;
           margin-top: 1.625rem;
           color: rgb(142, 142, 142);
           color: var(--grey);
           opacity: 0.7;
       }
       .not-found-back-button {
           margin-top: 3.75rem;
           border: 1px solid rgb(0, 90, 235);
           border: 1px solid var(--primary);
           border-radius: 38px;
           padding: 1rem 3.75rem;
           color: rgb(0, 90, 235);
           color: var(--primary);
       }

       .not-found-back-button:hover {
           margin-top: 3.75rem;
           border: 1px solid rgb(0, 90, 235);
           border: 1px solid var(--primary);
           border-radius: 38px;
           padding: 1rem 3.75rem;
           background-color: rgb(0, 90, 235);
           background-color: var(--primary);
           color: #fff;
           color: var(--white);
       }
       .not-found-home-button {
           margin-top: 3.75rem;
           border: 1px solid rgb(0, 90, 235);
           border: 1px solid var(--primary);
           border-radius: 38px;
           padding: 1rem 3.75rem;
           background-color: rgb(0, 90, 235);
           background-color: var(--primary);
           color: #fff;
           color: var(--white);
       }

       .not-found-home-button:hover {
           margin-top: 3.75rem;
           border: 1px solid rgb(0, 90, 235);
           border: 1px solid var(--primary);
           border-radius: 38px;
           padding: 1rem 3.75rem;
           background-color: transparent;
           color: rgb(0, 90, 235);
           color: var(--primary);
       }
   `]
})
export class NoInternetComponent {
}
