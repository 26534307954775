// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  api : 'https://fundsfairway.com:81/'

  //--==> dev
 // api: 'https://valueat.valuefy.com/ff-dev-api/',

  // //--==> demo
  //api: 'https://valueat.valuefy.com/ff-demo-api/',

  //--==> windows
  //api: 'https://localhost:44392/',

  //--==> mac
 //api: 'https://localhost:5003/',

  // //--==> prod -1
  //api: 'https://valueat.valuefy.com/fundsfairway-api/',

  //api: 'https://valueat.valuefy.com/ff-reg-api/',
};
