import { Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output } from '@angular/core';

@Directive({
  selector: '[appChkScrollEnd]'
})
export class ChkScrollEndDirective implements OnChanges {
  @Input() isApiLoad = false; @Output() emitEndScroll = new EventEmitter();
  @Input() addHeight = 100; @Input() isScrollDown = false; @Input() isScrollUp = false;
  @Input() isScrollToStartPosition = false;
 
  constructor(private el: ElementRef) { }
  @HostListener('scroll', ['$event']) onScroll(e: any) {
    /*console.log(`scrollTop:-  ${e.target.scrollTop};
    offsetTop:- ${e.target.offsetTop};
    scrollHeight:- ${e.target.scrollHeight}
    window.innerHeight:- ${e.target.scrollHeight}`);*/
    if (e.target.scrollTop + e.target.offsetTop + Number(this.addHeight) >= e.target.scrollHeight && this.isApiLoad) {
      //this.isApiLoad = false;
      this.emitEndScroll.emit();
    }
  }

  ngOnChanges() {
    if (this.isScrollDown) {
      this.el.nativeElement.scrollTop = this.el.nativeElement.scrollTop + 30;
    }
    if (this.isScrollUp) {
      this.el.nativeElement.scrollTop = this.el.nativeElement.scrollTop - 30;
    }
    if (this.isScrollToStartPosition) {
      this.el.nativeElement.scrollTop = 0;
    }
  }

}
