import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng5SliderModule } from 'ng5-slider';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DecimalNumberDirective } from 'src/app/core/directive/decimal-number.directive';

import { WidgetLoaderComponent } from './component/widget-loader/widget-loader.component';
import { PageNotFoundComponent } from './component/page-not-found/page-not-found';
import { AmcSearchComponent } from './component/amc-search/amc-search.component';
import { DynamicTblComponent } from './component/table/dynamic-tbl/dynamic-tbl.component';
import { Mul100Pipe } from 'src/app/core/pipes/mul100.pipe';
import { NoInternetComponent } from './component/no-internet/no-internet.component';
import { ChkScrollEndDirective } from 'src/app/core/directive/chk-scroll-end.directive';
import { AutoCompleteComponent } from './component/auto-complete/auto-complete.component';
import { DdlComponent } from './component/ddl/ddl/ddl.component';
import { SafeHtmlPipe } from 'src/app/core/pipes/safe-html.pipe';
import { ZeroDecimalBlankPipePipe } from 'src/app/core/pipes/zero-decimal-blank-pipe.pipe';

@NgModule({
  declarations: [ WidgetLoaderComponent, PageNotFoundComponent,
    AmcSearchComponent, DynamicTblComponent, DecimalNumberDirective, Mul100Pipe, NoInternetComponent,
    ChkScrollEndDirective, AutoCompleteComponent, DdlComponent,
    SafeHtmlPipe, ZeroDecimalBlankPipePipe],
  imports: [FormsModule, CommonModule, NgbModule, Ng5SliderModule],
  exports: [CommonModule, FormsModule, NgbModule, ReactiveFormsModule, DecimalNumberDirective, WidgetLoaderComponent,
    PageNotFoundComponent, AmcSearchComponent, DynamicTblComponent, Mul100Pipe, NoInternetComponent,
    ChkScrollEndDirective, AutoCompleteComponent, DdlComponent,
    SafeHtmlPipe, ZeroDecimalBlankPipePipe]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
    };
  }
}
