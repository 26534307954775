import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { consts } from 'src/app/core/utils/const';
import { NgbModal, ModalDismissReasons, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Cnvt } from 'src/app/core/utils/cnvt';
import { SharedService } from '../../shared.service';
import { Options } from 'ng5-slider';
import { AmcDashboardService } from 'src/app/modules/amc-dashboard/amc-dashboard.service';

@Component({
  selector: 'app-amc-search',
  templateUrl: './amc-search.component.html',
  styleUrls: ['./amc-search.component.scss']
})
export class AmcSearchComponent implements OnInit, OnChanges {
  minAum = 0; maxAum = 0;
  aumRange: any = {
    floor: 0,
    ceil: 500,
    showTicksValues: false,
    tickStep: 10,
    tickValueStep: 10,
    minRange: 10,
    pushRange: true,
    translate: (value: number): string => {
      return '$' + value + ' B';
    },
    combineLabels: (minAum: string, maxAum: string): string => {
      return 'from ' + minAum + ' up to ' + maxAum;
    }
  };
  public isLoaded = false; searchAmcData: any; closeResult = '';
  @ViewChild('filter', { static: false }) filter!: ElementRef;
  @Output() emitClearFilter = new EventEmitter(); @Output() emitApplyFilter = new EventEmitter();
  @Input() isFilterClick!: any; @Input() isClearClick!: any; @Input() lstSelectedFltr!: any;
  @Input() pageName = '';

  isSearch = false;
  selectedFilter = 'asset'; allFilterMst: any; curAssetID = 0; curAssetName = '';
  lstAsset: any; lstSyle: any = []; lstIssuer: any = []; lstSize: any = [];
  lstSector: any = []; lstCreditQuality: any = []; lstDuration: any = []; lstFactor: any = [];
  lstCountry: any = []; lstCurrency: any = []; searchSector = '';
  isVisible = {
    style: false, issuer: false, size: false, sector: false, cq: false, duration: false, factor: false
  };
  isSelectAll = {
    style: false, issuer: false, size: false, sector: false, cq: false, duration: false, factor: false
  };
  search = { sector: '', factor: '', country: '', currency: '' }; lstSummary: any = []; lstAfterApplySmry: any = [];
  objSearch = {
    hdr: [] as any, det: [] as any, searchTxtVal: '', isTxtSearch: false
  }; totalAssetPercent = 0;
  hdrFltr = {
    minAum: 0, maxAum: 0, isHedgedShare: false, isAccumulation: false,
    isDistributionShare: false, timeHorizone: 1, isUnHedgedShare: false
  };
  selectedPercent = {
    style: 0, issuer: 0, size: 0, sector: 0, cq: 0, duration: 0, factor: 0
  };
  isSearchDisabled = false; isFltrApplied = false;
  constructor(private share: SharedService,
    private config: NgbModalConfig, private modalService: NgbModal, private amcDashboardService: AmcDashboardService) { }

  ngOnInit() {
    this.config.backdrop = 'static'; this.config.keyboard = false;
  }

  ngOnChanges() {
    setTimeout(() => { this.isFltrApplied = this.chkIsFltrApplied(); }, 1);
    if (this.isFilterClick) {
      this.openModal(this.filter);
      this.isFilterClick = false; this.isClearClick = false;
    }
    if (this.isClearClick) {
      this.clearAll();
      this.isFilterClick = false; this.isClearClick = false;
    }
  }

  getFilterMst() {
    this.isLoaded = false;
    if (!this.allFilterMst) {
      const apiUrl = (this.pageName === 'market-intel') ? consts.api.mktIntel.filterMst : consts.api.amc.filterMst;
      this.share.get(apiUrl).subscribe((res: any) => {
        try {
          if (res.statusCode !== 200) {
            throw res;
          }
          this.allFilterMst = JSON.parse(res.data);
          this.lstAsset = this.allFilterMst.asset;
          this.curAssetID = this.lstAsset[0].asset_id; this.curAssetName = this.lstAsset[0].asset_name;
          this.totalAssetPercent = 0;
          this.lstSyle = []; this.lstIssuer = []; this.lstSize = []; this.lstSector = [];
          this.lstCreditQuality = []; this.lstDuration = []; this.lstFactor = []; this.lstCountry = [];
          this.lstCurrency = [];
          { // push filter
            this.lstAsset.forEach((v: any) => {
              this.totalAssetPercent = this.totalAssetPercent + v.percent;
            });
            this.allFilterMst.style.filter((v: any) => {
              this.lstSyle.push({
                asset_id: v.asset_id,
                style_name: v.style_name,
                style_id: v.style_id,
                percent: v.percent,
                isSelected: false,
              });
            });
            this.allFilterMst.issuer.filter((v: any) => {
              this.lstIssuer.push({
                asset_id: v.asset_id,
                issuer_name: v.issuer_name,
                issuer_id: v.issuer_id,
                percent: v.percent,
                isSelected: false,
              });
            });
            this.allFilterMst.size.filter((v: any) => {
              this.lstSize.push({
                asset_id: v.asset_id,
                size_id: v.size_id,
                size_name: v.size_name,
                percent: v.percent,
                isSelected: false,
              });
            });
            this.allFilterMst.sector.filter((v: any) => {
              this.lstSector.push({
                asset_id: v.asset_id,
                sector_id: v.sector_id,
                sector_name: v.sector_name,
                percent: v.percent,
                isSelected: false,
                isSearch: true,
              });
            });
            this.allFilterMst.credit_quality.filter((v: any) => {
              this.lstCreditQuality.push({
                asset_id: v.asset_id,
                cq_id: v.cq_id,
                cq_name: v.cq_name,
                percent: v.percent,
                isSelected: false,
              });
            });
            this.allFilterMst.duration.filter((v: any) => {
              this.lstDuration.push({
                asset_id: v.asset_id,
                duration_id: v.duration_id,
                duration_name: v.duration_name,
                percent: v.percent,
                isSelected: false,
              });
            });
            this.allFilterMst.factor.filter((v: any) => {
              this.lstFactor.push({
                asset_id: v.asset_id,
                factor_id: v.factor_id,
                factor_name: v.factor_name,
                percent: v.percent,
                isSelected: false,
                isSearch: true,
              });
            });
            this.allFilterMst.country.filter((v: any) => {
              this.lstCountry.push({
                country_id: v.country_id,
                country_name: v.country_name,
                isSelected: false,
                isSearch: true,
              });
            });
            this.allFilterMst.currency.filter((v: any) => {
              this.lstCurrency.push({
                currency_id: v.code,
                currency_name: v.text,
                isSelected: false,
                isSearch: true,
              });
            });
            {//range
              this.minAum = Math.floor(this.allFilterMst.hdr[0].min_aum);
              this.maxAum = Math.floor(this.allFilterMst.hdr[0].max_aum);
              const newOptions: Options = Object.assign({}, this.aumRange);
              newOptions.floor = this.minAum;
              newOptions.ceil = this.maxAum;
              this.aumRange = newOptions;
            }

            //sorting country in ascending order
            this.lstCountry = Cnvt.sort_v1(this.lstCountry, 'country_name', true);
            this.lstCurrency = Cnvt.sort_v1(this.lstCurrency, 'currency_name', true);
          }
          this.isLoaded = true;
          if (this.lstSelectedFltr) {
            this.bindSelectedVal();
          }
          if (this.objSearch.searchTxtVal !== '') {
            this.bindSearchTextFltr();
          }
          this.onAssetChange(this.curAssetID, this.curAssetName);
        } catch (ex) {
          console.log(ex);
        } finally {
          this.isLoaded = true;
        }
      });
    } else {
      this.isLoaded = true;
      if (this.lstSelectedFltr) {
        this.bindSelectedVal();
      }
      this.onAssetChange(this.curAssetID, this.curAssetName);
    }
  }

  onAssetChange(curAssetID: number, curAssetName: string) {
    this.selectedFilter = 'asset';
    this.curAssetID = curAssetID; this.curAssetName = curAssetName;
    this.isVisible.style = false; this.isVisible.issuer = false; this.isVisible.size = false;
    this.isVisible.sector = false; this.isVisible.cq = false; this.isVisible.duration = false; this.isVisible.factor = false;
    switch (curAssetName) {
      case 'Money Market': //'Money Market':
        this.isVisible.issuer = true; this.isVisible.cq = true; this.isVisible.duration = true;
        this.isSelectAll.issuer = this.getSelectAll('issuer');
        this.isSelectAll.cq = this.getSelectAll('cq');
        this.isSelectAll.duration = this.getSelectAll('duration');
        break;
      case 'Fixed Income': // 'Fixed Income':
        this.isVisible.style = true; this.isVisible.issuer = true; this.isVisible.cq = true;
        this.isVisible.duration = true;
        this.isSelectAll.style = this.getSelectAll('style');
        this.isSelectAll.issuer = this.getSelectAll('issuer');
        this.isSelectAll.cq = this.getSelectAll('cq');
        this.isSelectAll.duration = this.getSelectAll('duration');
        break;
      case 'Equity': // 'Equity':
        this.isVisible.style = true; this.isVisible.size = true; this.isVisible.sector = true;
        this.isVisible.factor = true;
        this.isSelectAll.style = this.getSelectAll('style');
        this.isSelectAll.size = this.getSelectAll('size');
        this.isSelectAll.sector = this.getSelectAll('sector');
        this.isSelectAll.factor = this.getSelectAll('factor');
        break;
      case 'Alternative': // 'Alternative':
        this.isVisible.style = true;
        this.isSelectAll.style = this.getSelectAll('style');
        break;
      case 'Commodity': //'Commodities':
        this.isVisible.style = true;
        this.isSelectAll.style = this.getSelectAll('style');
        break;
      case 'Real Estate': //Real Estate
        this.isVisible.style = true;
        this.isSelectAll.style = this.getSelectAll('style');
        break;
      case 'Multi-Asset': //'Portfolio Fund':
        this.isVisible.style = true;
        this.isSelectAll.style = this.getSelectAll('style');
        break;
    }
  }

  onSearchTxtFltr() {
    this.objSearch.hdr = []; this.objSearch.det = [];
    if (this.objSearch.searchTxtVal.length < 3) {
      return;
    }
    this.isSearchDisabled = true;
    if (!this.allFilterMst) {
      setTimeout(() => {
        this.getFilterMst();
      }, 1000);
    } else {
      this.bindSearchTextFltr();
    }
  }

  bindSearchTextFltr() {
    let tmp = []; const value = this.objSearch.searchTxtVal;

    {//get assest Value
      this.lstAsset.forEach((v: any) => {
        if (v.asset_name.toUpperCase().indexOf(this.objSearch.searchTxtVal.toUpperCase()) >= 0) {
          this.objSearch.hdr.push({
            asset_id: v.asset_id,
            asset_name: v.asset_name,
          });
        }
      });
    }

    {//add asset sub-set for respective asset
      this.objSearch.hdr.forEach((vh: any) => {

        {//style
          tmp = this.lstSyle.filter((vd: any) => {
            return vh.asset_id === vd.asset_id;
          });
          tmp.forEach((vd: any) => {
            this.objSearch.det.push({
              asset_id: vd.asset_id,
              asset_name: this.GetAssetNameById(vd.asset_id),
              moduleName: 'style',
              value: vd.style_name,
              id: vd.style_id,
              percent: vd.percent,
              isSelected: vd.isSelected,
            });
          });
        }

        {//issuer
          tmp = this.lstIssuer.filter((vd: any) => {
            return vh.asset_id === vd.asset_id;
          });
          tmp.forEach((vd: any) => {
            this.objSearch.det.push({
              asset_id: vd.asset_id,
              asset_name: this.GetAssetNameById(vd.asset_id),
              moduleName: 'issuer',
              value: vd.issuer_name,
              id: vd.issuer_id,
              percent: vd.percent,
              isSelected: vd.isSelected,
            });
          });
        }

        {//size
          tmp = this.lstSize.filter((vd: any) => {
            return vh.asset_id === vd.asset_id;
          });
          tmp.forEach((vd: any) => {
            this.objSearch.det.push({
              asset_id: vd.asset_id,
              asset_name: this.GetAssetNameById(vd.asset_id),
              moduleName: 'size',
              value: vd.size_name,
              id: vd.size_id,
              percent: vd.percent,
              isSelected: vd.isSelected,
            });
          });
        }

        {//sector
          tmp = this.lstSector.filter((vd: any) => {
            return vh.asset_id === vd.asset_id;
          });
          tmp.forEach((vd: any) => {
            this.objSearch.det.push({
              asset_id: vd.asset_id,
              asset_name: this.GetAssetNameById(vd.asset_id),
              moduleName: 'sector',
              value: vd.sector_name,
              id: vd.sector_id,
              percent: vd.percent,
              isSelected: vd.isSelected,
            });
          });
        }

        {//cq
          tmp = this.lstCreditQuality.filter((vd: any) => {
            return vh.asset_id === vd.asset_id;
          });
          tmp.forEach((vd: any) => {
            this.objSearch.det.push({
              asset_id: vd.asset_id,
              asset_name: this.GetAssetNameById(vd.asset_id),
              moduleName: 'cq',
              value: vd.cq_name,
              id: vd.cq_id,
              percent: vd.percent,
              isSelected: vd.isSelected,
            });
          });
        }

        {//duration
          tmp = this.lstDuration.filter((vd: any) => {
            return vh.asset_id === vd.asset_id;
          });
          tmp.forEach((vd: any) => {
            this.objSearch.det.push({
              asset_id: vd.asset_id,
              asset_name: this.GetAssetNameById(vd.asset_id),
              moduleName: 'duration',
              value: vd.duration_name,
              id: vd.duration_id,
              percent: vd.percent,
              isSelected: vd.isSelected,
            });
          });
        }
      });
    }

    {//get det value

      {//style
        const allowedIn = ['Fixed Income', 'Equity', 'Alternative', 'Commodity', 'Real Estate', 'Multi-Asset'];
        this.lstSyle.forEach((v: any) => {
          if (v.style_name.toUpperCase().indexOf(value.toUpperCase()) >= 0) {
            const assetName = this.GetAssetNameById(v.asset_id);
            if (!allowedIn.includes(assetName)) {
              return;
            }
            this.objSearch.hdr.push({
              asset_id: v.asset_id,
              asset_name: assetName
            });
            this.objSearch.det.push({
              asset_id: v.asset_id,
              asset_name: assetName,
              moduleName: 'style',
              value: v.style_name,
              id: v.style_id,
              percent: v.percent,
              isSelected: v.isSelected,
            });
          }
        });
      }

      {//issuer
        const allowedIn = ['Money Market', 'Fixed Income'];
        this.lstIssuer.forEach((v: any) => {
          if (v.issuer_name.toUpperCase().indexOf(value.toUpperCase()) >= 0) {
            const assetName = this.GetAssetNameById(v.asset_id);
            if (!allowedIn.includes(assetName)) {
              return;
            }
            this.objSearch.hdr.push({
              asset_id: v.asset_id,
              asset_name: assetName
            });
            this.objSearch.det.push({
              asset_id: v.asset_id,
              asset_name: this.GetAssetNameById(v.asset_id),
              moduleName: 'issuer',
              value: v.issuer_name,
              id: v.issuer_id,
              percent: v.percent,
              isSelected: v.isSelected,
            });
          }
        });
      }

      {//size
        const allowedIn = ['Equity'];
        this.lstSize.forEach((v: any) => {
          if (v.size_name.toUpperCase().indexOf(value.toUpperCase()) >= 0) {
            const assetName = this.GetAssetNameById(v.asset_id);
            if (!allowedIn.includes(assetName)) {
              return;
            }
            this.objSearch.hdr.push({
              asset_id: v.asset_id,
              asset_name: assetName
            });
            this.objSearch.det.push({
              asset_id: v.asset_id,
              asset_name: assetName,
              moduleName: 'size',
              value: v.size_name,
              id: v.size_id,
              percent: v.percent,
              isSelected: v.isSelected,
            });
          }
        });
      }

      {//sector
        const allowedIn = ['Equity'];
        this.lstSector.forEach((v: any) => {
          if (v.sector_name.toUpperCase().indexOf(value.toUpperCase()) >= 0) {
            const assetName = this.GetAssetNameById(v.asset_id);
            if (!allowedIn.includes(assetName)) {
              return;
            }
            this.objSearch.hdr.push({
              asset_id: v.asset_id,
              asset_name: assetName
            });
            this.objSearch.det.push({
              asset_id: v.asset_id,
              asset_name: assetName,
              moduleName: 'sector',
              value: v.sector_name,
              id: v.sector_id,
              percent: v.percent,
              isSelected: v.isSelected,
            });
          }
        });
      }

      {//cq
        const allowedIn = ['Money Market', 'Fixed Income'];
        this.lstCreditQuality.forEach((v: any) => {
          if (v.cq_name.toUpperCase().indexOf(value.toUpperCase()) >= 0) {
            const assetName = this.GetAssetNameById(v.asset_id);
            if (!allowedIn.includes(assetName)) {
              return;
            }
            this.objSearch.hdr.push({
              asset_id: v.asset_id,
              asset_name: assetName
            });
            this.objSearch.det.push({
              asset_id: v.asset_id,
              asset_name: assetName,
              moduleName: 'cq',
              value: v.cq_name,
              id: v.cq_id,
              percent: v.percent,
              isSelected: v.isSelected,
            });
          }
        });
      }

      {//duration
        const allowedIn = ['Money Market', 'Fixed Income'];
        this.lstDuration.forEach((v: any) => {
          if (v.duration_name.toUpperCase().indexOf(value.toUpperCase()) >= 0) {
            const assetName = this.GetAssetNameById(v.asset_id);
            if (!allowedIn.includes(assetName)) {
              return;
            }
            this.objSearch.hdr.push({
              asset_id: v.asset_id,
              asset_name: assetName
            });
            this.objSearch.det.push({
              asset_id: v.asset_id,
              asset_name: assetName,
              moduleName: 'duration',
              value: v.duration_name,
              id: v.duration_id,
              percent: v.percent,
              isSelected: v.isSelected,
            });
          }
        });
      }

      {//factor
        const allowedIn = ['Equity'];
        this.lstFactor.forEach((v: any) => {
          if (v.factor_name.toUpperCase().indexOf(value.toUpperCase()) >= 0) {
            const assetName = this.GetAssetNameById(v.asset_id);
            if (!allowedIn.includes(assetName)) {
              return;
            }
            this.objSearch.hdr.push({
              asset_id: v.asset_id,
              asset_name: assetName
            });
            this.objSearch.det.push({
              asset_id: v.asset_id,
              asset_name: assetName,
              moduleName: 'factor',
              value: v.factor_name,
              id: v.factor_id,
              percent: v.percent,
              isSelected: v.isSelected,
            });
          }
        });
      }

      {//country
        this.lstCountry.forEach((v: any) => {
          if (v.country_name.toUpperCase().indexOf(value.toUpperCase()) >= 0) {
            this.objSearch.hdr.push({
              asset_id: -1,
              asset_name: 'Country'
            });
            this.objSearch.det.push({
              asset_id: -1,
              asset_name: v.country_name,
              moduleName: 'country',
              value: v.country_name,
              id: v.country_id,
              percent: undefined,
              isSelected: v.isSelected,
            });
          }
        });

      }

      {//currency
        this.lstCurrency.forEach((v: any) => {
          if (v.currency_name.toUpperCase().indexOf(value.toUpperCase()) >= 0) {
            this.objSearch.hdr.push({
              asset_id: 0,
              asset_name: 'Currency'
            });
            this.objSearch.det.push({
              asset_id: 0,
              asset_name: v.currency_name,
              moduleName: 'currency',
              value: v.currency_name,
              id: v.currency_id,
              percent: undefined,
              isSelected: v.isSelected,
            });
          }
        });
      }
    }

    this.objSearch.hdr = Cnvt.unique(this.objSearch.hdr);
    this.objSearch.det = Cnvt.unique(this.objSearch.det);

    {//select the checkbox for data in summay list
      this.lstAfterApplySmry.forEach((v: any) => {
        this.objSearch.det.forEach((vd: any) => {
          if (v.asset_id === vd.asset_id && v.moduleName === vd.moduleName && v.id === vd.id) {
            vd.isSelected = true;
          }
        });
      });
    }

    if (this.objSearch.hdr.length > 0) {
      this.objSearch.isTxtSearch = true;
    }
    this.isSearchDisabled = false;
  }

  GetAssetNameById(asset_id: number): string {
    let res = '';
    for (const v of this.lstAsset) {
      if (v.asset_id === asset_id) {
        res = v.asset_name;
        break;
      }
    }
    return res;
  }

  onSelectAll(moduleName: string, e: any) {
    switch (moduleName) {
      case 'style':
        this.selectedPercent.style = 0;
        this.lstSyle.forEach((v: any) => {
          if (v.asset_id === this.curAssetID) {
            v.isSelected = e.currentTarget.checked;
            if (e.currentTarget.checked) {
              this.selectedPercent.style = this.selectedPercent.style + v.percent;
              this.removeSummary(moduleName, v.style_id, this.curAssetID);
              this.lstSummary.push({
                asset_id: v.asset_id, asset_name: this.curAssetName,
                moduleName: 'style', value: v.style_name, id: v.style_id
              });
            }
          }
        });
        if (!e.currentTarget.checked) {
          this.lstSummary = this.lstSummary.filter((sv: any) => {
            return (sv.moduleName !== 'style');
          });
          this.selectedPercent.style = 0;
        }
        break;
      case 'issuer':
        this.selectedPercent.issuer = 0;
        this.lstIssuer.forEach((v: any) => {
          if (v.asset_id === this.curAssetID) {
            v.isSelected = e.currentTarget.checked;
            if (e.currentTarget.checked) {
              this.selectedPercent.issuer = this.selectedPercent.issuer + v.percent;
              this.removeSummary(moduleName, v.issuer_id, this.curAssetID);
              this.lstSummary.push({
                asset_id: v.asset_id, asset_name: this.curAssetName,
                moduleName: 'issuer', value: v.issuer_name, id: v.issuer_id
              });
            }
          }
        });
        if (!e.currentTarget.checked) {
          this.lstSummary = this.lstSummary.filter((sv: any) => {
            return (sv.moduleName !== 'issuer');
          });
          this.selectedPercent.issuer = 0;
        }
        break;
      case 'size':
        this.selectedPercent.size = 0;
        this.lstSize.forEach((v: any) => {
          if (v.asset_id === this.curAssetID) {
            v.isSelected = e.currentTarget.checked;
            if (e.currentTarget.checked) {
              this.selectedPercent.size = this.selectedPercent.size + v.percent;
              this.removeSummary(moduleName, v.size_id, this.curAssetID);
              this.lstSummary.push({
                asset_id: v.asset_id, asset_name: this.curAssetName,
                moduleName: 'size', value: v.size_name, id: v.size_id
              });
            }
          }
        });
        if (!e.currentTarget.checked) {
          this.lstSummary = this.lstSummary.filter((sv: any) => {
            return (sv.moduleName !== 'size');
          });
          this.selectedPercent.size = 0;
        }
        break;
      case 'sector':
        this.selectedPercent.sector = 0;
        this.lstSector.forEach((v: any) => {
          if (v.asset_id === this.curAssetID) {
            v.isSelected = e.currentTarget.checked;
            if (e.currentTarget.checked) {
              this.selectedPercent.sector = this.selectedPercent.sector + v.percent;
              this.removeSummary(moduleName, v.sector_id, this.curAssetID);
              this.lstSummary.push({
                asset_id: v.asset_id, asset_name: this.curAssetName,
                moduleName: 'sector', value: v.sector_name, id: v.sector_id
              });
            }
          }
        });
        if (!e.currentTarget.checked) {
          this.lstSummary = this.lstSummary.filter((sv: any) => {
            return (sv.moduleName !== 'sector');
          });
          this.selectedPercent.sector = 0;
        }
        break;
      case 'cq':
        this.selectedPercent.cq = 0;
        this.lstCreditQuality.forEach((v: any) => {
          if (v.asset_id === this.curAssetID) {
            v.isSelected = e.currentTarget.checked;
            if (e.currentTarget.checked) {
              this.selectedPercent.cq = this.selectedPercent.cq + v.percent;
              this.removeSummary(moduleName, v.cq_id, this.curAssetID);
              this.lstSummary.push({
                asset_id: v.asset_id, asset_name: this.curAssetName,
                moduleName: 'cq', value: v.cq_name, id: v.cq_id
              });
            }
          }
        });
        if (!e.currentTarget.checked) {
          this.lstSummary = this.lstSummary.filter((sv: any) => {
            return (sv.moduleName !== 'cq');
          });
          this.selectedPercent.cq = 0;
        }
        break;
      case 'duration':
        this.selectedPercent.duration = 0;
        this.lstDuration.forEach((v: any) => {
          if (v.asset_id === this.curAssetID) {
            v.isSelected = e.currentTarget.checked;
            if (e.currentTarget.checked) {
              this.selectedPercent.duration = this.selectedPercent.duration + v.percent;
              this.removeSummary(moduleName, v.duration_id, this.curAssetID);
              this.lstSummary.push({
                asset_id: v.asset_id, asset_name: this.curAssetName,
                moduleName: 'duration', value: v.duration_name, id: v.duration_id
              });
            }
          }
        });
        if (!e.currentTarget.checked) {
          this.lstSummary = this.lstSummary.filter((sv: any) => {
            return (sv.moduleName !== 'duration');
          });
          this.selectedPercent.duration = 0;
        }
        break;
      default:
        this.selectedPercent.factor = 0;
        this.lstFactor.forEach((v: any) => {
          if (v.asset_id === this.curAssetID) {
            v.isSelected = e.currentTarget.checked;
            if (e.currentTarget.checked) {
              this.selectedPercent.factor = this.selectedPercent.factor + v.percent;
              this.lstSummary.push({
                asset_id: v.asset_id, asset_name: this.curAssetName,
                moduleName: 'factor', value: v.factor_name, id: v.factor_id
              });
            }
          }
        });
        if (!e.currentTarget.checked) {
          this.lstSummary = this.lstSummary.filter((sv: any) => {
            return (sv.moduleName !== 'factor');
          });
          this.selectedPercent.factor = 0;
        }
        break;
    }
  }

  onSelectSingle(moduleName: string, id: number, asset_id: number) {
    switch (moduleName) {
      case 'style':
        for (const v of this.lstSyle) {
          if (v.style_id === id && v.asset_id === asset_id) {
            v.isSelected = !v.isSelected;
            if (v.isSelected) {
              this.lstSummary.push({
                asset_id: v.asset_id, asset_name: this.curAssetName,
                moduleName: 'style', value: v.style_name, id: v.style_id
              });
            } else {
              this.removeSummary(moduleName, id, asset_id);
            }
            break;
          }
        }
        this.isSelectAll.style = this.getSelectAll('style');
        break;
      case 'issuer':
        for (const v of this.lstIssuer) {
          if (v.issuer_id === id && v.asset_id === asset_id) {
            v.isSelected = !v.isSelected;
            if (v.isSelected) {
              this.lstSummary.push({
                asset_id: v.asset_id, asset_name: this.curAssetName,
                moduleName: 'issuer', value: v.issuer_name, id: v.issuer_id
              });
            } else {
              this.removeSummary(moduleName, id, asset_id);
            }
            break;
          }
        }
        this.isSelectAll.issuer = this.getSelectAll('issuer');
        break;
      case 'size':
        for (const v of this.lstSize) {
          if (v.size_id === id && v.asset_id === asset_id) {
            v.isSelected = !v.isSelected;
            if (v.isSelected) {
              this.lstSummary.push({
                asset_id: v.asset_id, asset_name: this.curAssetName,
                moduleName: 'size', value: v.size_name, id: v.size_id
              });
            } else {
              this.removeSummary(moduleName, id, asset_id);
            }
            break;
          }
        }
        this.isSelectAll.size = this.getSelectAll('size');
        break;
      case 'sector':
        for (const v of this.lstSector) {
          if (v.sector_id === id && v.asset_id === asset_id) {
            v.isSelected = !v.isSelected;
            if (v.isSelected) {
              this.lstSummary.push({
                asset_id: v.asset_id, asset_name: this.curAssetName,
                moduleName: 'sector', value: v.sector_name, id: v.sector_id
              });
            } else {
              this.removeSummary(moduleName, id, asset_id);
            }
            break;
          }
        }
        this.isSelectAll.sector = this.getSelectAll('sector');
        break;
      case 'cq':
        for (const v of this.lstCreditQuality) {
          if (v.cq_id === id && v.asset_id === asset_id) {
            v.isSelected = !v.isSelected;
            if (v.isSelected) {
              this.lstSummary.push({
                asset_id: v.asset_id, asset_name: this.curAssetName,
                moduleName: 'cq', value: v.cq_name, id: v.cq_id
              });
            } else {
              this.removeSummary(moduleName, id, asset_id);
            }
            break;
          }
        }
        this.isSelectAll.cq = this.getSelectAll('cq');
        break;
      case 'duration':
        for (const v of this.lstDuration) {
          if (v.duration_id === id && v.asset_id === asset_id) {
            v.isSelected = !v.isSelected;
            if (v.isSelected) {
              this.lstSummary.push({
                asset_id: v.asset_id, asset_name: this.curAssetName,
                moduleName: 'duration', value: v.duration_name, id: v.duration_id
              });
            } else {
              this.removeSummary(moduleName, id, asset_id);
            }
            break;
          }
        }
        this.isSelectAll.duration = this.getSelectAll('duration');
        break;
      case 'factor':
        for (const v of this.lstFactor) {
          if (v.factor_id === id && v.asset_id === asset_id) {
            v.isSelected = !v.isSelected;
            if (v.isSelected) {
              this.lstSummary.push({
                asset_id: v.asset_id, asset_name: this.curAssetName,
                moduleName: 'factor', value: v.factor_name, id: v.factor_id
              });
            } else {
              this.removeSummary(moduleName, id, asset_id);
            }
            break;
          }
        }
        this.isSelectAll.factor = this.getSelectAll('factor');
        break;
      case 'country':

        for (const v of this.lstCountry) {
          if (v.country_id === id) {
            v.isSelected = !v.isSelected;
            if (v.isSelected) {
              this.lstSummary.push({ moduleName: 'country', value: v.country_name, id: v.country_id });
            } else {
              this.removeSummary(moduleName, id, asset_id);
            }
            break;
          }
        }
        break;
      default:
        for (const v of this.lstCurrency) {
          if (v.currency_id === id) {
            v.isSelected = !v.isSelected;
            if (v.isSelected) {
              this.lstSummary.push({ moduleName: 'currency', value: v.currency_name, id: v.currency_id });
            } else {
              this.removeSummary(moduleName, id, asset_id);
            }
            break;
          }
        }
        break;
    }
  }

  searchFilter(moduleName: string) {
    switch (moduleName) {
      case 'factor':
        this.lstFactor.filter((v: any) => {
          v.isSearch = (v.factor_name.toUpperCase().indexOf(this.search.factor.toUpperCase()) >= 0) ? true : false;
        });
        break;
      case 'sector':
        this.lstSector.filter((v: any) => {
          v.isSearch = (v.sector_name.toUpperCase().indexOf(this.search.sector.toUpperCase()) >= 0) ? true : false;
        });
        break;
      case 'country':
        this.lstCountry.filter((v: any) => {
          v.isSearch = (v.country_name.toUpperCase().indexOf(this.search.country.toUpperCase()) >= 0) ? true : false;
        });
        break;
      default:
        this.lstCurrency.filter((v: any) => {
          v.isSearch = (v.currency_name.toUpperCase().indexOf(this.search.currency.toUpperCase()) >= 0) ? true : false;
        });
        break;
    }
  }

  clearAll() {
    setTimeout(() => { this.isFltrApplied = this.chkIsFltrApplied(); }, 1);
    this.objSearch.isTxtSearch = false; this.objSearch.searchTxtVal = '';
    this.lstSummary = [];
    //this.selectedFilter = 'asset';
    // if (this.lstAsset) {
    //   this.curAssetID = this.lstAsset[0].asset_id; this.curAssetName = this.lstAsset[0].asset_name;
    // }
    this.search.factor = ''; this.search.sector = ''; this.search.country = ''; this.search.currency = '';
    this.lstSyle.forEach((v: any) => { v.isSelected = false; });
    this.lstIssuer.forEach((v: any) => { v.isSelected = false; });
    this.lstSize.forEach((v: any) => { v.isSelected = false; });
    this.lstSector.forEach((v: any) => { v.isSelected = false; v.isSearch = true; });
    this.lstCreditQuality.forEach((v: any) => { v.isSelected = false; });
    this.lstDuration.forEach((v: any) => { v.isSelected = false; });
    this.lstFactor.forEach((v: any) => { v.isSelected = false; v.isSearch = true; });
    this.lstCountry.forEach((v: any) => { v.isSelected = false; v.isSearch = true; });
    this.lstCurrency.forEach((v: any) => { v.isSelected = false; v.isSearch = true; });
    //this.onAssetChange(this.curAssetID, this.curAssetName);
    this.objSearch.hdr = []; this.objSearch.det = []; this.objSearch.searchTxtVal = '';
    this.hdrFltr.isAccumulation = false; this.hdrFltr.isDistributionShare = false;
    this.hdrFltr.isHedgedShare = false;
    this.hdrFltr.isUnHedgedShare = false;
    // this.isSelectAll.style = false;
    this.isSelectAll = {
      style: false, issuer: false, size: false, sector: false, cq: false, duration: false, factor: false
    };
    {//range
      if (this.allFilterMst) {
        this.minAum = Math.floor(this.allFilterMst.hdr[0].min_aum);
        this.maxAum = Math.floor(this.allFilterMst.hdr[0].max_aum);
        const newOptions: Options = Object.assign({}, this.aumRange);
        newOptions.floor = this.minAum;
        newOptions.ceil = this.maxAum;
        this.aumRange = newOptions;
      }
    }
    this.lstAfterApplySmry = []; this.amcDashboardService.filterMst = [];
    setTimeout(() => {
      this.share.lstSelectedFltr = this.lstAfterApplySmry;
    }, 1);
    this.emitClearFilter.emit();
  }

  applyAll() {
    this.objSearch.isTxtSearch = false; this.objSearch.searchTxtVal = '';
    this.hdrFltr.minAum = this.minAum; this.hdrFltr.maxAum = this.maxAum;
    this.lstSummary.push({ hdr: this.hdrFltr }); this.lstSummary = Cnvt.unique(this.lstSummary);
    this.lstAfterApplySmry = [...this.lstSummary]; this.amcDashboardService.filterMst = [];
    setTimeout(() => {
      this.share.lstSelectedFltr = this.lstAfterApplySmry;
      setTimeout(() => { this.isFltrApplied = this.chkIsFltrApplied(); }, 1);
    }, 1);
    this.emitApplyFilter.emit(this.lstSummary);
  }

  private getSelectAll(moduleName: string): boolean {
    let isSelected = false; let isSelectedCnt = 0; let totalLength = 0;
    switch (moduleName) {
      case 'style':
        this.selectedPercent.style = 0;
        this.lstSyle.forEach((v: any) => {
          if (v.asset_id === this.curAssetID) {
            totalLength++;
            if (v.isSelected) {
              isSelectedCnt++;
              this.selectedPercent.style = this.selectedPercent.style + v.percent;
            }
          }
        });
        this.lstSyle = Cnvt.sort_v1(this.lstSyle, 'style_name', true);
        isSelected = (totalLength === isSelectedCnt) ? true : false;
        this.isVisible.style = totalLength === 0 ? false : true;
        break;
      case 'issuer':
        this.selectedPercent.issuer = 0;
        this.lstIssuer.forEach((v: any) => {
          if (v.asset_id === this.curAssetID) {
            totalLength++;
            if (v.isSelected) {
              isSelectedCnt++;
              this.selectedPercent.issuer = this.selectedPercent.issuer + v.percent;
            }
          }
        });
        this.lstIssuer = Cnvt.sort_v1(this.lstIssuer, 'issuer_name', true);
        isSelected = (totalLength === isSelectedCnt) ? true : false;
        this.isVisible.issuer = totalLength === 0 ? false : true;
        break;
      case 'size':
        this.selectedPercent.size = 0;
        this.lstSize.forEach((v: any) => {
          if (v.asset_id === this.curAssetID) {
            totalLength++;
            if (v.isSelected) {
              isSelectedCnt++;
              this.selectedPercent.size = this.selectedPercent.size + v.percent;
            }
          }
        });
        this.lstSize = Cnvt.sort_v1(this.lstSize, 'size_name', true);
        isSelected = (totalLength === isSelectedCnt) ? true : false;
        this.isVisible.size = totalLength === 0 ? false : true;
        break;
      case 'sector':
        this.selectedPercent.sector = 0;
        this.lstSector.forEach((v: any) => {
          if (v.asset_id === this.curAssetID) {
            totalLength++;
            if (v.isSelected) {
              isSelectedCnt++;
              this.selectedPercent.sector = this.selectedPercent.sector + v.percent;
            }
          }
        });
        this.lstSector = Cnvt.sort_v1(this.lstSector, 'sector_name', true);
        isSelected = (totalLength === isSelectedCnt) ? true : false;
        this.isVisible.sector = totalLength === 0 ? false : true;
        break;
      case 'cq':
        this.selectedPercent.cq = 0;
        this.lstCreditQuality.forEach((v: any) => {
          if (v.asset_id === this.curAssetID) {
            totalLength++;
            if (v.isSelected) {
              isSelectedCnt++;
              this.selectedPercent.cq = this.selectedPercent.cq + v.percent;
            }
          }
        });
        this.lstCreditQuality = Cnvt.sort_v1(this.lstCreditQuality, 'cq_name', true);
        isSelected = (totalLength === isSelectedCnt) ? true : false;
        this.isVisible.cq = totalLength === 0 ? false : true;
        break;
      case 'duration':
        this.selectedPercent.duration = 0;
        this.lstDuration.forEach((v: any) => {
          if (v.asset_id === this.curAssetID) {
            totalLength++;
            if (v.isSelected) {
              isSelectedCnt++;
              this.selectedPercent.duration = this.selectedPercent.duration + v.percent;
            }
          }
        });
        this.lstDuration = Cnvt.sort_v1(this.lstDuration, 'duration_name', true);
        isSelected = (totalLength === isSelectedCnt) ? true : false;
        this.isVisible.duration = totalLength === 0 ? false : true;
        break;
      default:
        this.selectedPercent.factor = 0;
        this.lstFactor.forEach((v: any) => {
          if (v.asset_id === this.curAssetID) {
            totalLength++;
            if (v.isSelected) {
              isSelectedCnt++;
              this.selectedPercent.factor = this.selectedPercent.factor + v.percent;
            }
          }
        });
        this.lstFactor = Cnvt.sort_v1(this.lstFactor, 'factor_name', true);
        isSelected = ((totalLength === isSelectedCnt) && totalLength > 0) ? true : false;
        this.isVisible.factor = totalLength === 0 ? false : true;
        break;
    }
    return isSelected;
  }

  private removeSummary(moduleName: string, id: number, asset_id: number) {
    let i = 0;
    for (const sv of this.lstSummary) {
      if (sv.moduleName === moduleName && sv.id === id) {
        if (!sv.asset_id) { // apart from asset
          this.lstSummary.splice(i, 1); break;
        } else if (sv.asset_id === asset_id) {
          this.lstSummary.splice(i, 1); break;
        }
      }
      i++;
    }
  }

  private bindSelectedVal() {
    const data = JSON.parse(this.lstSelectedFltr); let hdrPosition = 0;
    data.forEach((sv: any, k: number) => {
      if (sv.hdr) { hdrPosition = k };
      switch (sv.moduleName) {
        case 'style':
          for (const v of this.lstSyle) {
            if (v.style_id === sv.id && v.asset_id === sv.asset_id) {
              this.removeSummary('style', sv.id, v.asset_id);
              v.isSelected = true;
              this.lstSummary.push({
                asset_id: v.asset_id, asset_name: this.GetAssetNameById(v.asset_id),
                moduleName: 'style', value: v.style_name, id: v.style_id
              });
            }
          }
          this.isSelectAll.style = this.getSelectAll('style');
          break;
        case 'issuer':
          for (const v of this.lstIssuer) {
            if (v.issuer_id === sv.id && v.asset_id === sv.asset_id) {
              this.removeSummary('issuer', sv.id, v.asset_id);
              v.isSelected = true;
              this.lstSummary.push({
                asset_id: v.asset_id, asset_name: this.GetAssetNameById(v.asset_id),
                moduleName: 'issuer', value: v.issuer_name, id: v.issuer_id
              });
            }
          }
          this.isSelectAll.style = this.getSelectAll('issuer');
          break;
        case 'size':
          for (const v of this.lstSize) {
            if (v.size_id === sv.id && v.asset_id === sv.asset_id) {
              this.removeSummary('size', sv.id, v.asset_id);
              v.isSelected = true;
              this.lstSummary.push({
                asset_id: v.asset_id, asset_name: this.GetAssetNameById(v.asset_id),
                moduleName: 'size', value: v.size_name, id: v.size_id
              });
            }
          }
          this.isSelectAll.style = this.getSelectAll('size');
          break;
        case 'sector':
          for (const v of this.lstSector) {
            if (v.sector_id === sv.id && v.asset_id === sv.asset_id) {
              this.removeSummary('sector', sv.id, v.asset_id);
              v.isSelected = true;
              this.lstSummary.push({
                asset_id: v.asset_id, asset_name: this.GetAssetNameById(v.asset_id),
                moduleName: 'sector', value: v.sector_name, id: v.sector_id
              });
            }
          }
          this.isSelectAll.style = this.getSelectAll('sector');
          break;
        case 'cq':
          for (const v of this.lstCreditQuality) {
            if (v.cq_id === sv.id && v.asset_id === sv.asset_id) {
              this.removeSummary('cq', sv.id, v.asset_id);
              v.isSelected = true;
              this.lstSummary.push({
                asset_id: v.asset_id, asset_name: this.GetAssetNameById(v.asset_id),
                moduleName: 'cq', value: v.cq_name, id: v.cq_id
              });
            }
          }
          this.isSelectAll.style = this.getSelectAll('cq');
          break;
        case 'duration':
          for (const v of this.lstDuration) {
            if (v.duration_id === sv.id && v.asset_id === sv.asset_id) {
              this.removeSummary('duration', sv.id, v.asset_id);
              v.isSelected = true;
              this.lstSummary.push({
                asset_id: v.asset_id, asset_name: this.GetAssetNameById(v.asset_id),
                moduleName: 'duration', value: v.duration_name, id: v.duration_id
              });
            }
          }
          this.isSelectAll.style = this.getSelectAll('duration');
          break;
        case 'factor':
          for (const v of this.lstFactor) {
            if (v.factor_id === sv.id && v.asset_id === sv.asset_id) {
              this.removeSummary('factor', sv.id, v.asset_id);
              v.isSelected = true;
              this.lstSummary.push({
                asset_id: v.asset_id, asset_name: this.GetAssetNameById(v.asset_id),
                moduleName: 'factor', value: v.factor_name, id: v.factor_id
              });
            }
          }
          this.isSelectAll.style = this.getSelectAll('factor');
          break;
        case 'country':
          for (const v of this.lstCountry) {
            if (v.country_id === sv.id) {
              this.removeSummary('country', sv.id, v.asset_id);
              v.isSelected = true;
              this.lstSummary.push({ moduleName: 'country', value: v.country_name, id: v.country_id });
            }
          }
          this.isSelectAll.style = this.getSelectAll('country');
          break;
        case 'currency':
          for (const v of this.lstCurrency) {
            if (v.currency_id === sv.id) {
              this.removeSummary('currency', sv.id, v.asset_id);
              v.isSelected = true;
              this.lstSummary.push({ moduleName: 'currency', value: v.currency_name, id: v.currency_id });
            }
          }
          this.isSelectAll.style = this.getSelectAll('currency');
          break;
      }
    });

    {//header handling 
      if (data.length === 0 || !data[hdrPosition].hdr) {
        return;
      }
      const hdr = data[hdrPosition].hdr;
      this.hdrFltr.isHedgedShare = hdr.isHedgedShare;
      this.hdrFltr.isUnHedgedShare = hdr.isUnHedgedShare;
      this.hdrFltr.isAccumulation = hdr.isAccumulation;
      this.hdrFltr.isDistributionShare = hdr.isDistributionShare;
    }

  }

  openModal(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl', windowClass: 'filter-modal' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.getFilterMst();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  private chkIsFltrApplied(): boolean {
    let res = false;
    const data = JSON.parse(this.lstSelectedFltr); let hdrPosition = 0;
    if (Cnvt.IsObjectNullOrEmpty(data)) {
      return false;
    }
    data.forEach((sv: any, k: number) => {
      if (sv.hdr) { hdrPosition = k };
    });
    if (data.length === 0) {
      return false;
    }
    if (data.length > 1) {
      return true;
    }
    if (data[hdrPosition].hdr) {
      const hdr = data[hdrPosition].hdr;
      if (hdr.isHedgedShare || hdr.isUnHedgedShare || hdr.isAccumulation || hdr.isDistributionShare) {
        return true;
      }
    }
    return res;
  }

}
