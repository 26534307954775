import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthenticateGuard } from './core/utils/auth/authenticate.guard';
import { AuthorizeGuard } from './core/utils/auth/authorize.guard';
import { NoInternetComponent } from './modules/shared/component/no-internet/no-internet.component';
import { PageNotFoundComponent } from './modules/shared/component/page-not-found/page-not-found';


//prueba guard 
import {PruebaEmtGuard} from './prueba-emt.guard'
var routes: Routes = [
  { path: '', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
 // { path: 'emt',  loadChildren: () => import('./modules/emt/emt-module.module').then(m => m.EmtModuleModule), canActivate: [AuthenticateGuard, AuthorizeGuard] },
  {path: 'assessment-of-value',loadChildren: () => import('./modules/assessment-of-value/assestmen-of-value.module').then(m => m.AssestsModule),canActivate: [PruebaEmtGuard] },
  {path: 'portfolio-search',loadChildren: () => import('./modules/portfolio-search/portfolio.module').then(m => m.PortfolioModule),canActivate: [PruebaEmtGuard] },
  {path: 'FCA-permissions',loadChildren: () => import('./modules/fca-permissions/fca-permissions.module').then(m => m.FcaPermissionsModule),canActivate: [PruebaEmtGuard] },

  //{path: 'periodical-reports',loadChildren: () => import('./modules/emt/emt-module.module').then(m => m.EmtModuleModule),canActivate: [PruebaEmtGuard] },
  { path: 'profile', loadChildren: () => import('./modules/profile-overview/profile-overview.module').then(m => m.ProfileOverviewModule), canActivate: [PruebaEmtGuard] },
  { path: 'amcdashboard', loadChildren: () => import('./modules/amc-dashboard/amc-dashboard.module').then(m => m.AmcDashboardModule), canActivate: [PruebaEmtGuard] },
  { path: 'fundDashboard', loadChildren: () => import('./modules/fund-dashboard/fund-dashboard.module').then(m => m.FundDashboardModule), canActivate: [PruebaEmtGuard] },
  { path: 'amcAnalytics', loadChildren: () => import('./modules/amc-analytics/amc-analytics.module').then(m => m.AmcAnalyticsModule), canActivate: [PruebaEmtGuard] },
  { path: 'competitiveAnalysis', loadChildren: () => import('./modules/competitive-analysis/competitive-analysis.module').then(m => m.CompetitiveAnalysisModule), canActivate: [ PruebaEmtGuard] },
  { path: 'market-intelligence', loadChildren: () => import('./modules/market-intelligence/market-intelligence.module').then(m => m.MarketIntelligenceModule), canActivate: [ PruebaEmtGuard] },
  { path: 'value-for-money', loadChildren: () => import('./modules/competitive-analysis/competitive-analysis.module').then(m => m.CompetitiveAnalysisModule), canActivate: [ PruebaEmtGuard] },
  { path: 'eagle-eye', loadChildren: () => import('./modules/eagle-eye/eagle-eye.module').then(m => m.EagleEyeModule), canActivate: [PruebaEmtGuard] },
  { path: 'corporate-action', loadChildren: () => import('./modules/corporate-action/corporate-action.module').then(m => m.CorporateActionModule), canActivate: [PruebaEmtGuard] },
  { path: 'portfolio-report', loadChildren: () => import('./modules/portfolio-report/portfolio-report.module').then(m => m.PortfolioReportModule), canActivate: [PruebaEmtGuard] },
  { path: 'no-internet', component: NoInternetComponent, canActivate: [AuthenticateGuard] },
  { path: 'page-not-found', component: PageNotFoundComponent, canActivate: [PruebaEmtGuard] },
  { path: 'user', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule), canActivate: [AuthenticateGuard]},
  { path: '**', component: PageNotFoundComponent },
];

/*const idsArray = ["1013", "1014", "1015"];
const idsArrayOnlyAssessment = ["1011","1012"]
const userId:any = localStorage.getItem("userID")
if(idsArray.includes(userId)){
  routes = []
  routes = [
    { path: '', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
    {path: 'assessment-of-value',loadChildren: () => import('./modules/assessment-of-value/assestmen-of-value.module').then(m => m.AssestsModule),canActivate: [PruebaEmtGuard] },
    { path: 'no-internet', component: NoInternetComponent, canActivate: [AuthenticateGuard] },
    { path: 'page-not-found', component: PageNotFoundComponent, canActivate: [AuthenticateGuard] },
    { path: 'user', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
    { path: '**', component: PageNotFoundComponent }
  ]

}else if(idsArrayOnlyAssessment.includes(userId)){
  routes = []
  routes = [
    { path: '', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
    { path: 'fundDashboard', loadChildren: () => import('./modules/fund-dashboard/fund-dashboard.module').then(m => m.FundDashboardModule), canActivate: [PruebaEmtGuard] },
    {path: 'assessment-of-value',loadChildren: () => import('./modules/assessment-of-value/assestmen-of-value.module').then(m => m.AssestsModule),canActivate: [PruebaEmtGuard] },
    { path: 'corporate-action', loadChildren: () => import('./modules/corporate-action/corporate-action.module').then(m => m.CorporateActionModule), canActivate: [PruebaEmtGuard] },
    { path: 'eagle-eye', loadChildren: () => import('./modules/eagle-eye/eagle-eye.module').then(m => m.EagleEyeModule), canActivate: [PruebaEmtGuard] },

    { path: 'no-internet', component: NoInternetComponent, canActivate: [AuthenticateGuard] },
    { path: 'page-not-found', component: PageNotFoundComponent, canActivate: [AuthenticateGuard] },
    { path: 'user', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
    { path: '**', component: PageNotFoundComponent }
  ]

}else{

}*/

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'top', useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
