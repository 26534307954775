/*
This table contain the below feature :-
1. isDate :- date formatting with, need to add in th;
2. isClick :- click on td, need to add in th;
3. isPercent :- percent formatting, need to add in th;
4. isNumber :- number formater, is convert the number to fix & common separated,
    need to add in th;
4.1 isInt :- number formater, is convert the number to fix at 0 decimal & common separated,
    need to add in th;
5. groupName :- to get the th header name for grouping, need to add in th
6. isHide :- to hide a column, need to add in th
7. isExtraCol :- to open popup that allow to hide of show the column, need to pass in isOpenColumn @input
    Used in case when there is more column to show.
8. need to implement sub-click
9. mul 100 functionality
10. i icon :- for information showing as a tooltip
11. custom-table-tooltip:- convert the long string to ellipse, need to add in th class variable;
12. isDisableColSort : - to disable column level sorting
13. isHtml :- to have html element in the td
14. html :- to html element with sanitizer string
15. thClass :- to add class into th
16. isColHtml :-  to have col html in tr with tooltip
17. ddl in td :- to get ddl in table
18. class : to add class to a div
19. isReload : to reload the table
*/

import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ElementRef, ViewChild, Injectable } from '@angular/core';
import { Cnvt } from 'src/app/core/utils/cnvt';
import { NgbModal, ModalDismissReasons, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { consts } from 'src/app/core/utils/const';
import { AuthService } from 'src/app/core/utils/auth/auth.service';
import { SharedService } from 'src/app/modules/shared/shared.service';
import { Tbl, TblStaticSetting } from '../../../common-model';
import { CorporateActionService } from 'src/app/modules/corporate-action/corporate-action.service';
import { Router } from '@angular/router';
import { PortfolioReportService } from 'src/app/modules/portfolio-report/portfolio-report.service';
declare var require: any;
const FileSaver = require('file-saver');

@Component({
  selector: 'app-dynamic-tbl',
  templateUrl: 'dynamic-tbl.component.html',
  styles: [`.table-filter-container {
    justify-content: flex-end;
  }
.selected-filter-remove {
  height: 8px;
  width: 8px;
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: 300;
}
.selected-filter-list {
  display: flex;
  align-items: center;
  padding: 0.7rem;
  border: 1px solid;
  margin: 1rem 0;
  border-radius: 8px;
  flex-wrap: wrap;
  max-height: 5.8rem;
  overflow-y: auto;
}
  .selected-filter {
    margin-bottom: 6px;
    margin-right: 6px;
    cursor: pointer;
  }
.search-list-container {
  max-height: 10rem;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem;
}

.custom-table-tooltip h2 {
  position: relative;
  @include fontStyling(14, 400);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

.border-radius
{
  border-top-left-radius: 10px;
}

.filter-icon-table {
  height: 12px;
  width: auto;
  color: rgb(0, 90, 235);
  color: var(--primary);
}

.document-fix {
  position: sticky;
  right: 0;
  background-color: #f6f6f6;
  z-index: 0;
  text-align: center;
}

.pagination-container{
  float: none;
}

.pagination-section{
  justify-content: right;
  flex-wrap: wrap;
}

.letter-pagination{
  justify-content: center;
  flex-wrap: wrap;
  display: -webkit-box;
  display: flex;
  webkit-box-align: center;
  align-items: center;
}


.alphabet-letter {
  padding: 5px;
  font-size: 1rem;
  font-weight: 400;
}

.alphabet-letter:hover{
    border-radius: 50%;
    cursor:pointer;
    background-color: var(--primaryLight);
}


  `]
})
@Injectable({
  providedIn: 'root'
})
export class DynamicTblComponent implements OnInit, OnChanges {
  @Output() emitDownloadExcel = new EventEmitter();
  @Output() showDataExcel = new EventEmitter();
  @Input() addCa: any;
  @Input() tblData!: Tbl; @Input() isApiLoaded!: any; @Input() isExtraColClick = false; @Input() isStateSave: any;
  @Output() emitTblEvnt = new EventEmitter();
  @Output() emitTblEvnt2 = new EventEmitter();
  @Output() emitOnSearch = new EventEmitter(); @Output() emitOnSort = new EventEmitter();
  @Output() emitOnPaging = new EventEmitter(); isSearch = false;
  @ViewChild('extraColumn', { static: false }) extraColumn!: ElementRef; closeResult: any;
  arrPaging: any[] = []; noRecord = false; totalRecord = 0; paginationLen = 5; lstGroup: any[] = [];
  searchExtraCol = ''; extraColTh: any = {};
  isSelectAll = false; bkpStaticData = [] as any; ss: TblStaticSetting | undefined; isThHide = false;
  alphabetArray: string[] = Array.from({length: 26}, (_, i) => String.fromCharCode('a'.charCodeAt(0) + i));
  filterActive:boolean = true;
  constructor(
    private authService: AuthService, 
    private config: NgbModalConfig,
    private modalService: NgbModal, 
    public share: SharedService , 
    public caService: CorporateActionService,
    private router: Router,
    private prSer: PortfolioReportService
     ) {
  }

  isInReport: boolean;
  showPaggingDefinition:boolean = false;
  shouldShowDefinition() {
    // Coloca la lógica de tu condición aquí
    if(this.tblData.th.some(item => item.name === 'Definition')){
       this.showPaggingDefinition = true

    }else{
      this.showPaggingDefinition = false
    }
  }


  ngOnInit() {

    this.addCa = false
    const match = this.router.url.match(/\/portfolio-report\//)

    this.isInReport = (!!match && match.length > 0);      
    this.config.backdrop = 'static'; this.config.keyboard = false;

    for (let i = 0; i < this.alphabetArray.length; i++) {
      this.alphabetArray[i] = this.alphabetArray[i].charAt(0).toUpperCase() + this.alphabetArray[i].slice(1);
      }
      this.shouldShowDefinition()
  }

  ngOnChanges() {
    if (!this.isApiLoaded) {
      for(let i = 0; i < this.tblData.data.length; i ++){
        if(this.tblData.data[i]["Dividend Outflow"] === "C" || this.tblData.data[i]["Dividend Outflow"] === "c" ){
          this.tblData.data[i]["Dividend Outflow"] = "Cash"
        }else{
          null
        }
      }
      return;
    }

    {// on reload
      if (this.tblData.isReload) {
        this.isSearch = false;
        this.arrPaging = []; this.noRecord = false; this.totalRecord = 0; this.paginationLen = 5;
        this.lstGroup = []; this.searchExtraCol = ''; this.extraColTh = {};
        this.isSelectAll = false; this.bkpStaticData = [];
        return;
      }
    }

    //open extra column pop-up
    {
      if (this.isExtraColClick && this.tblData.isExtraCol) {
        try {
          this.openModal(this.extraColumn);
          this.extraColTh = JSON.parse(JSON.stringify(this.tblData.th));
          this.extraColTh.map((i: any) => i.isSearch = true);
          return;
        } catch (ex) {
          console.log('issue in ngOnChanges ' + ex);
        }
      }
    }

    {// download xl
      const xl = this.tblData.xlDownloadSetting;
      if (xl && xl.isXlDownload && xl.isXlDownloadBtnClick) {
        this.isApiLoaded = true; this.downloadXl();
        return;
      }
    }

    {//is static search
      const st = this.ss;
      if (st && st.enable) {
        this.onStaticTblSearch();
      }
    }
    this.bindTable();
  }

  bindTable() {
    try {
      this.ss = this.tblData.staticSetting;
      {//static tbl binding
        if (this.ss) {
          this.tblData.data = [];
          if (this.ss.isDefaultSorting) {
            this.staticSorting();
          } else {
            const startPage = (this.tblData.page_num * this.tblData.pageLen) - this.tblData.pageLen,
              endPage = this.tblData.page_num * this.tblData.pageLen;
            if (!this.ss.data) {
              throw 'staticData is not set';
            }
            for (const v of this.ss.data) {
              if (!v.row_num) {
                throw 'row_num key is not set';
              }
              if (v.row_num > startPage && v.row_num <= endPage) {
                this.tblData.data.push(v);
              }
              if (this.tblData.data.length > this.tblData.pageLen) {
                break;
              }
            }
          }
          this.tblData.tableTotalRecord = this.ss.data.length;
        }
      }

      {//grouping
        this.grouping();
      }

      {//no record
        this.isThHide = false;
        if (!this.tblData.data) {
          this.noRecord = true;
          return false;
        }
        this.noRecord = (this.tblData.data.length === 0) ? true : false;
        if (this.noRecord && this.tblData.isNoRecordImg) {
          this.isThHide = true;
        }
      }

      {//paging
        if (this.tblData.pageLen <= this.tblData.tableTotalRecord) {
          this.totalRecord = Math.ceil(this.tblData.tableTotalRecord / this.tblData.pageLen);
          this.getPaging();
        } else {
          this.arrPaging = [];
        }
      }


    } catch (ex) {
      this.noRecord = true;
      console.log('issue in bindTable ' + ex);
    } finally {
      this.isApiLoaded = true;
    }
  }

  private getPaging() {

    this.arrPaging = [];
    if (this.totalRecord <= 1) {
      return;
    }

    {//if less than equal to paginationLen
      if (this.totalRecord <= this.paginationLen) {
        for (let i = 1; i <= this.totalRecord; i++) {
          this.arrPaging.push(i);
        }
        return;
      }
    }

    {//1st 2
      if (this.tblData.page_num <= 2) {
        for (let i = 1; i <= this.paginationLen; i++) {
          this.arrPaging.push(i);
        }
        return;
      }
    }

    {//last 2
      if (this.tblData.page_num > (this.totalRecord - 2)) {
        const lst2 = this.totalRecord - this.tblData.page_num;
        const prev = lst2 === 0 ? 4 : 2;
        for (let i = this.tblData.page_num - (lst2 + prev); i <= (this.tblData.page_num + lst2); i++) {
          this.arrPaging.push(i);
        }
        return;
      }
    }

    {//midddle
      for (let i = this.tblData.page_num - 2; i <= this.tblData.page_num + 2; i++) {
        this.arrPaging.push(i);
      }
    }
  }

  sorting(data: any) {
    const col_name = data.col_name, isAsc = data.isAsc;
    if (this.tblData.isDisableSorting || data.isDisableColSort) {
      return;
    }
    this.isApiLoaded = false;
    this.tblData.order_by = isAsc ? 'desc' : 'asc';
    this.tblData.order_col_name = col_name;
    this.tblData.th.filter((v: any) => {
      if (v.col_name === col_name) {
        v.isAsc = !isAsc;
        return;
      }
    });
    this.tblData.page_num = 1;

    {//static sorting
      if (this.ss) {
        this.staticSorting();
      }
    }
  //  this.emitTblEvnt2.emit({ operation: 'sorting', tblData: this.tblData });

    this.emitTblEvnt.emit({ operation: 'sorting', tblData: this.tblData });
  }

  paging(pageNum: any) {
    if (this.tblData.page_num === pageNum) {
      return;
    }
    this.isApiLoaded = false; this.tblData.page_num = pageNum;

    {//static paging
      if (this.ss) {
        this.staticPaging();
      }
    }
  //  this.emitTblEvnt2.emit({ operation: 'paging', tblData: this.tblData });

    this.emitTblEvnt.emit({ operation: 'paging', tblData: this.tblData });
    //window.scroll(0, 0);
  }

  previous() {
    if (this.tblData.page_num === 1) {
      return;
    }
    this.isApiLoaded = false; this.tblData.page_num = this.tblData.page_num - 1;
  //  this.tblData.page_num = this.tblData.page_num - 1;
    {//static paging
      if (this.ss) {
        this.staticPaging();
      }
    }
   // this.emitTblEvnt2.emit({ operation: 'previous', tblData: this.tblData });

    this.emitTblEvnt.emit({ operation: 'previous', tblData: this.tblData });
  }

  next() {
    if (this.tblData.page_num === this.totalRecord) {
      return;
    }
    this.isApiLoaded = false; this.tblData.page_num = this.tblData.page_num + 1;

    {//static paging
      if (this.ss) {
        this.staticPaging();
      }
    }
    //this.emitTblEvnt2.emit({ operation: 'next', tblData: this.tblData });

    this.emitTblEvnt.emit({ operation: 'next', tblData: this.tblData });
  }

  tdClick(data: any, th: any) {
    if(th.name === "Download Document"){
     // console.log(th)
      const dataExcel = data
      this.emitDownloadExcel.emit(dataExcel)

    }if(th.name === "View Document"){
      const dataExcel = data

     // this.emitTblEvnt2.emit({ operation: 'tdClick', tdData: dataExcel, th: th });
     this.emitTblEvnt.emit({ operation: 'tdClick', tdData: data, th: th , routeApi:"best_execution"});

    
    }else{
    if (!th.isClick || data.total_score === null || data[th.col_name] === '') {
      return;
    }
    this.emitTblEvnt.emit({ operation: 'tdClick', tdData: data, th: th, routeApi:"client_portfolio" });
  }
  }

  first() {
    if (this.tblData.page_num === 1) {
      return;
    }
    this.isApiLoaded = false; this.tblData.page_num = 1;

    {//static paging
      if (this.ss) {
        this.staticPaging();
      }
    }
    //this.emitTblEvnt2.emit({ operation: 'first', tblData: this.tblData });

    this.emitTblEvnt.emit({ operation: 'first', tblData: this.tblData });
  }

  last() {
    if (this.tblData.page_num === this.totalRecord) {
      return;
    }
    this.isApiLoaded = false; this.tblData.page_num = this.totalRecord;

    {//static paging
      if (this.ss) {
        this.staticPaging();
      }
    }
    //this.emitTblEvnt2.emit({ operation: 'last', tblData: this.tblData });

    this.emitTblEvnt.emit({ operation: 'last', tblData: this.tblData });
  }

  private grouping() {
    const tmpArr = [] as any; this.lstGroup = [];
    if (this.tblData.isGroup && this.tblData.th[0].groupName) {
      this.tblData.th.forEach((v: any) => {
        if (!v.isHide) {
          tmpArr.push(v.groupName);
        }
      });
    }
    Cnvt.unique(tmpArr).forEach((v: any) => {
      this.lstGroup.push({
        groupName: v, colspan: tmpArr.filter((vg: any) => {
          return v === vg;
        }).length
      });
    });
  }

  applyExtraCol() {
    const selectedData = this.extraColTh.filter((i: any) => {
      return i.isHide === false;
    });
    if (selectedData.length === 0) {
      this.share.showInfo('Please select atleast one value', '');
      return;
    }
    this.tblData.th = JSON.parse(JSON.stringify(this.extraColTh));
    this.grouping();
    this.modalService.dismissAll();
    if (this.isStateSave) {
      let visibleTableHeader = this.tblData.th.map((val) => {
        let obj = {
          col_name: val.col_name,
          isHide: val.isHide
        };
        return obj;
      });

      let columnState = {
        tableHeader: visibleTableHeader
      }
      this.share.setModuleWiseColumnState(this.share.user_id, this.share.currentPageId, JSON.stringify(columnState));
    }
  }

  clearExtraCol() {
    this.extraColTh.forEach((v: any) => { v.isHide = true; });
  }

  onSearchExtraCol() {
    this.extraColTh.filter((v: any) => {
      v.isSearch = (v.name.toUpperCase().indexOf(this.searchExtraCol.toUpperCase()) >= 0) ? true : false;
    });
  }

  openModal(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl', windowClass: 'filter-modal' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  public downloadXl() {
    const xl = Cnvt.copyObj(this.tblData.xlDownloadSetting);
    xl.xlData.th.forEach((vt: any) => {
      {//remove hidden field
        if (vt.isHide) {
          xl.xlData.data.forEach((vd: any) => {
            delete vd['' + vt.col_name + ''];
          });
        }
      }
    });

    {// delete hidden th
      xl.xlData.th = xl.xlData.th.filter((vt: any) => {
        return !vt.isHide;
      });
    }

    {// call api
      xl.xlData.data = JSON.stringify(xl.xlData.data);
      this.authService.post(consts.api.common.table2Xl, xl).subscribe((res: any) => {
        try {
          FileSaver(new Blob([res]), xl.fileName + '.xlsx');
        } catch (ex) {
          console.log(ex);
        } finally {
          this.emitTblEvnt.emit({ operation: 'xlDownloaded', tblData: this.tblData });
        }
      }, (error: any) => {
        console.log(error);
      });
    }
  }

  private staticPaging() {
    if (!this.ss) {
      return;
    }
    this.tblData.data = [];
    const startPage = (this.tblData.page_num * this.tblData.pageLen) - this.tblData.pageLen,
      endPage = this.tblData.page_num * this.tblData.pageLen;
    for (const v of this.ss.data) {
      if (v.row_num > startPage && v.row_num <= endPage) {
        this.tblData.data.push(v);
      }
    }
    this.getPaging();
    this.isApiLoaded = true;
  }

  private staticSorting() {
    let dataType = 'string', isAsc = false;
    for (const v of this.tblData.th) {
      if (v.col_name === this.tblData.order_col_name && v.isAsc) {
        isAsc = v.isAsc;
        if (v.isString || v.isNumber) {
          dataType = 'string';
        }
        if (v.isDate) {
          dataType = 'date';
        }
        break;
      }
    }
    let arrTmp: any = [];
    if (!this.ss) {
      return;
    }
    switch (dataType) {
      case 'string':
        arrTmp = Cnvt.sort_v1(this.ss.data, this.tblData.order_col_name, isAsc);
        break;
      case 'date':
        arrTmp = Cnvt.sortDt(this.ss.data, this.tblData.order_col_name, isAsc);
        break;
    }
    arrTmp.forEach((v: any, n: number) => {
      v.row_num = n + 1;
    }); //this.tblData.data = arrTmp;
    this.staticPaging();
  }

  changeAllSelection() {
    const isChecked = !this.isSelectAll;
    this.extraColTh.forEach((i: any) => {
      i.isHide = isChecked;
    });
  }

  checkallSelection() {
    const allSelected = this.extraColTh.filter((i: any) => {
      return i.isHide === false;
    });

    const allDeSelected = this.extraColTh.filter((i: any) => {
      return i.isHide === true;
    });

    if (allSelected.length === this.extraColTh.length) {
      this.isSelectAll = true;
    } else if (allDeSelected.length === this.extraColTh.length) {
      this.isSelectAll = false;
    } else {
      this.isSelectAll = false;
    }

  }

   // Definitions Data new Filter 
  

   clearFilterLetter(){
    this.filterActive = true;
    const abcArray = this.caService.definitionData.sort((a:any, b:any) => {
      if (!a.corporate_action || typeof a.corporate_action !== "string") {
        return -1;
      }
      if (!b.corporate_action || typeof b.corporate_action !== "string") {
        return 1;
      }
      return a.corporate_action.localeCompare(b.corporate_action);
    });
    this.caService.definitionData = []
    this.caService.definitionData = abcArray;
    this.tblData.data = []
    this.tblData.data = this.caService.definitionData.slice(0,5)
  }



    filterLetter(letter:any){
      this.filterActive = false;
      var caServiceCopy = this.caService.definitionData;

      caServiceCopy.sort((a:any, b:any) => {
          if (a.corporate_action.startsWith(letter) && !b.corporate_action.startsWith(letter)) {
            return -1;
          }
          if (!a.corporate_action.startsWith(letter) && b.corporate_action.startsWith(letter)) {
            return 1;
          }
          return a.corporate_action.localeCompare(b.corporate_action);
        });

      let filteredArray = caServiceCopy.filter((element: { corporate_action: string ; })  => element.corporate_action.startsWith(letter));
      this.tblData.data = filteredArray
    }

  private onStaticTblSearch() {
    try {
      const ss = this.ss;
      if (!ss) {
        return;
      }
      if (ss.searchText === '') {
        ss.data = Cnvt.copyObj(this.bkpStaticData);
        return;
      }
      if (this.bkpStaticData.length === 0) {
        this.bkpStaticData = Cnvt.copyObj(ss.data);
      }
      if (!ss.searchColLst) {
        throw 'search column list is not set '
      }
      if (ss.searchColLst.length === 0) {
        this.tblData.th.forEach((vt: any) => {
          if (!vt.isHide) {
            ss.searchColLst.push(vt.col_name);
          }
        });
      }
      this.bkpStaticData.forEach((v: any) => {
        let data = '';
        Object.keys(v).forEach((vo: any) => {
          if (ss.searchColLst.includes(vo)) {
            data = this.isColDate(vo) ? (data + ' ' + Cnvt.formatDate(v[vo])) : (data + ' ' + v[vo]);
            v.isSearch = Cnvt.isKeywordFound(data, ss.searchText);
          }
        });
      });
      const tmp = Cnvt.copyObj(this.bkpStaticData).filter((v: any) => {
        return v.isSearch === true;
      });
      tmp.forEach((v: any, k: number) => {
        v.row_num = (k + 1);
      });
      ss.data = tmp;
    } catch (ex) {
      console.log('issue in DynamicTblComponent -> onStaticTblSearch ' + ex);
    }
  }

  private isColDate(colName: string) {
    let res = false;
    const tmp = this.tblData.th.filter((v: any) => {
      return v.col_name === colName;
    });
    if (tmp.length === 0) {
      res = false;
    } else {
      if (tmp[0].isDate)
        res = tmp[0].isDate;
    }
    return res;;

  }

}
